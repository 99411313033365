<template>
  <div class="ContactUs">
    <ContactUsComponent></ContactUsComponent>
  </div>
</template>

<style lang="scss" scoped>
// .ContentUs {
//   width: 100%;
//   height: 100%;
//   background-color: rgb(190, 82, 132);
// }
</style>

<script>
import ContactUsComponent from "@/components/contact-us/ContactUs.component.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "ContactsUsView",
  components: {
    ContactUsComponent,
  },
  created() {
    this.$store.commit("setPage", "contact");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: "ذكاء | تواصل معنا",
      meta: [
        {
          name: `description`,
          content: `
توصل معنا للاستفسار أو طلب بناء موقع أو تطبيق أو الحصول على تصميمات للمواقع وصفحات التواصل الاجتماعي.
          `,
        },

        {
          name: `keywords`,
          content: `Contact , contact us , تواصل معنا , ذكاء`,
        },
      ],
    });
  },
};
</script>
