<template>
  <div class="container">
    <div class="Jobs">
      <div class="contact">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="text">
                <h1>
                  كن واحدا من
                  <span> عائلة ذكاء الدولية </span>
                </h1>
                <h2>اصنع مستقبلك معنا!</h2>
                <h4>
                  ذكاء الدولية نقطة انطلاقك في كل من بيئات العمل الخاصة
                  بتكنولوجيا المعلومات والصناعة. واكتساب الخبرة المهنية وصقل
                  مهاراتك ورفع مستوى حياتك المهنية.
                </h4>
              </div>
            </div>
            <div class="col-sm-hidden col-md-6">
              <div class="photo">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/images/contact-img.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="team">
        <h2 class="team-header">الفوائد والامتيازات</h2>

        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="card">
              <div class="card-img">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/Frame.svg"
                  alt=""
                />
              </div>
              <div class="card-info">
                <h3>ساعات عمل مرنة</h3>
                <h4>
                  نحن نعلم مدى أهمية وجود توازن جيد بين العمل والحياة ونحترم ذلك
                  بعمق. سواء كنت تريد البدأ 8 صباحا او 10 صباحا نحن نقدر ذلك
                  ولكن من المهم أن تقضي 8 ساعات العمل اليومية.
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card">
              <div class="card-img">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/Frame-2.svg"
                  alt=""
                />
              </div>
              <div class="card-info">
                <h3>فريق جيد وأجواء رائعة</h3>
                <h4>
                  نحن نعمل يدا بيد من أجل صنع أشياء عظيمة. يعلم الجميع في فريقنا
                  أن التواصل الرائع والروح الطيبة هي وصفة الفريق السليم. بعد كل
                  شيء ، الأمر كله يتعلق بالناس بالفريق وقضاء وقت جيد ومنتج معًا.
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card">
              <div class="card-img">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/Frame-4.svg"
                  alt=""
                />
              </div>
              <div class="card-info">
                <h3>مشاريع متنوعة وتحديات مختلفة</h3>
                <h4>
                  تتمثل إحدى أهم مهماتنا في إيجاد مشاريع صعبة تساعدنا حقًا على
                  التعلم والنمو والتأثير في حياة الجميع. نهدف إلى إنشاء مواقع
                  الكترونية وتطبيقات جوال قوية وذات مغزى للشركات الكبيرة ولمؤسسي
                  الشركات الناشئة.
                </h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="card">
              <div class="card-img">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/Frame-3.svg"
                  alt=""
                />
              </div>
              <div class="card-info">
                <h3>وقت وأنشطة ممتعة</h3>
                <h4>
                  من بداية العمل الفريق إلى وقت الراحة والألعاب ، تتمثل رغبتنا
                  في إنشاء روابط قوية ومشاركة الضحكات والحصول على أكبر عدد ممكن
                  من الذكريات الممتعة. نريد الحفاظ على بيئة رائعة للجميع من خلال
                  تنظيم أنشطة مرحة.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="available-jobs">
        <h2 class="available-jobs-header">الوظائف المتاحة</h2>
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider
        >
          <ul
            class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid"
          >
            <!-- <li v-for="job in jobs" :key="job.id">
              <div class="uk-panel">
                <div class="card">
                  <div class="job-name">
                    <h3>
                      {{ job.title }}
                    </h3>
                  </div>

                  <h4>{{ job.description }}</h4>
                  <div class="job-info">
                    <div class="time">
                      <img
                        src="../../assets/job/mingcute_time-fill.svg"
                        alt=""
                      />
                      <span>{{ job.type }}</span>
                    </div>
                    <div class="site">
                      <img
                        src="../../assets/job/zondicons_location.svg"
                        alt=""
                      />
                      <span> {{ job.remote }}</span>
                    </div>
                  </div>
                  <router-link to="/request" class="btn">تقدم ألان</router-link>
                </div>
              </div>
            </li> -->
            <li>
              <div class="uk-panel">
                <div class="card">
                  <div class="job-name">
                    <h3>Full-Stack (php)</h3>
                  </div>
                  <h4>
                    هل أنت مطور Php طموح تبحث عن فرصة للعمل في فريق رائع وتطوير
                    مهاراتك وحياتك المهنية؟ انضم إلينا!
                  </h4>
                  <div class="job-info">
                    <div class="time">
                      <img
                        src="../../assets/job/mingcute_time-fill.svg"
                        alt=""
                      />
                      <span>دوام كلي</span>
                    </div>
                    <div class="site">
                      <img
                        src="../../assets/job/zondicons_location.svg"
                        alt=""
                      />
                      <span>من الشركة</span>
                    </div>
                  </div>
                  <router-link to="/request" class="btn">تقدم ألان</router-link>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <div class="card">
                  <div class="job-name">
                    <h3>E-content developer</h3>
                  </div>
                  <h4>
                    هل أنت مطور تعليم إلكتروني تبحث عن فرصة للعمل في فريق رائع
                    وتطوير مهاراتك ؟ انضم إلينا!
                  </h4>
                  <div class="job-info">
                    <div class="time">
                      <img
                        src="../../assets/job/mingcute_time-fill.svg"
                        alt=""
                      />
                      <span>دوام كلي</span>
                    </div>
                    <div class="site">
                      <img
                        src="../../assets/job/zondicons_location.svg"
                        alt=""
                      />
                      <span>من الشركة</span>
                    </div>
                  </div>
                  <router-link to="/request" class="btn">تقدم ألان</router-link>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <div class="card">
                  <div class="job-name">
                    <h3>UI/UX Designer</h3>
                  </div>
                  <h4>
                    اذا كنت مصمم تجربة مستخدم مبدع ومواكب للتصميمات العصرية انضم
                    إلينا وشاركنا!
                  </h4>
                  <div class="job-info">
                    <div class="time">
                      <img
                        src="../../assets/job/mingcute_time-fill.svg"
                        alt=""
                      />
                      <span>دوام كلي</span>
                    </div>
                    <div class="site">
                      <img
                        src="../../assets/job/zondicons_location.svg"
                        alt=""
                      />
                      <span>من الشركة</span>
                    </div>
                  </div>
                  <router-link to="/request" class="btn">تقدم ألان</router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="company">
        <h2 class="available-jobs-header">أجواء ممتعة</h2>

        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slider="autoplay:true ; autoplay-interval :2500"
        >
          <ul
            class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@xl uk-grid"
          >
            <!-- <li v-for="img in imgs" :key="img.id">
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  :src="
                    'http://localhost/thakaa-api/public/storage/photos/' +
                    img.image
                  "
                  alt="image"
                />
              </div>
            </li> -->
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-4.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-3.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-6.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-8.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-9.jpg"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div class="uk-panel">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/job/team-7.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.Jobs {
  margin: 100px 0px 50px;

  .contact {
    background-color: #cceeef;
    padding: 50px 30px;
    border-radius: 10px;

    .text {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      h1 {
        color: #0c7abd;
        font-size: 32px;
        font-family: Cairo;
        font-weight: 700;
        line-height: 50px;
        span {
          display: block;
        }
      }
      h2 {
        color: #12151c;
        font-size: 24px;
        font-family: Cairo;
        font-weight: 700;
      }
      h4 {
        color: #12151c;
        font-size: 16px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30px;
      }
    }

    .photo {
      width: 100%;
      height: 100% !important;
      text-align: end;
      img {
        width: 90%;
        margin: auto;
        border-radius: 8px;
      }
    }
  }

  .team {
    padding: 50px 0px 0px;
    h2 {
      color: #28649c;
      font-size: 18px;
      font-family: Cairo;
      font-weight: 700;
      margin: 20px 10px;
    }
    .card {
      // padding: 50px 0px 50px 50px
      padding: 25px;
      border: none;
      box-shadow: 0px 0px 5px rgba(202, 201, 201, 0.5);
      height: 100%;
      border-radius: 8px;
      img {
        width: 80px;
        height: 80px;
      }
      h3 {
        margin: 15px 0px 10px;
        color: #12151c;
        font-size: 16px;
        font-family: Cairo;
        font-weight: 700;
      }
      h4 {
        color: #12151c;
        font-size: 18px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 26px;
        text-align: justify;
      }
    }
  }

  .available-jobs {
    padding: 50px 0px 0px;
    h2 {
      color: #28649c;
      font-size: 18px;
      font-family: Cairo;
      font-weight: 700;
      margin: 20px 10px;
    }
    .uk-grid > * {
      /* padding-left: 40px; */
      padding: 0px 10px;
    }
    .card {
      margin: 10px auto;
      padding: 20px;
      border-radius: 10px;
      transition: 0.6s;
      transition-timing-function: ease-in-out;
      display: flex;
      justify-content: space-evenly;

      h3 {
        color: #12151c;
        font-size: 18px;
        font-family: Cairo;
        font-weight: 700;
        text-align: end;
        margin: 20px auto;
      }
      h4 {
        color: #12151c;
        font-size: 16px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 32px;
        text-align: justify;
      }
      .job-info {
        display: flex;
        margin: 20px 0px;

        img {
          width: 20px;
          height: 20px;
          margin: 0px 5px;
        }
        span {
          color: #8a8a8a;
          font-size: 14px;
          font-family: Cairo;
          font-weight: 400;
          letter-spacing: 0px;
        }
        .time {
          margin-left: 20px;
        }
      }
      .btn {
        background-color: $main-color;
        padding: 10px;
        width: fit-content;
        color: white;
        letter-spacing: 0px;
        &:hover {
          background-color: #fff;
          color: $main-color-alt;
          border: 1px solid #fff;
        }
      }
      &:hover {
        background-color: $main-color-alt;
        cursor: pointer;
        h3,
        h4,
        span {
          color: white;
        }
        .job-info {
          img {
            filter: brightness(200);
          }
        }
      }
    }
  }

  .company {
    padding: 50px 0px 0px;
    h2 {
      color: #28649c;
      font-size: 18px;
      font-family: Cairo;
      font-weight: 700;
      margin: 20px 10px;
    }
    img {
      width: 100%;
      height: 275px !important;
      border-radius: 8px;
    }
  }
}

@media (max-width: 575.98px) {
  .Jobs {
    .col-sm-hidden {
      display: none;
    }
    .available-jobs {
      padding: 20px 0px 0px;
      li {
        padding: 0px 10px;
      }
    }

    .contact .text h4 {
      margin-bottom: 0;
    }
    .team {
      padding: 20px 0px 0px;
      .col-lg-3 {
        padding: 3px;
        margin: 5px 0px;
      }
      .card h4 {
        font-size: 14px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
      }
    }
    .company {
      padding: 20px 10px 10px;
      .uk-grid > * {
        padding: 0px 5px !important;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Jobs {
    .col-sm-hidden {
      display: none;
    }
    .available-jobs {
      padding: 20px 0px 0px;
      li {
        padding: 0px 10px;
      }
      .card {
        height: 380px;
      }
    }
    .team {
      padding: 20px 0px 0px;
      .col-lg-3 {
        padding: 3px;
        margin: 5px 0px;
      }
      .card h4 {
        font-size: 14px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
      }
    }
    .company {
      padding: 20px 10px 10px;
      .uk-grid > * {
        padding: 0px 5px !important;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .Jobs {
    .available-jobs {
      li {
        padding: 0px 10px;
      }
      .card {
        height: 350px;
      }
    }
    .team {
      .col-lg-3 {
        padding: 3px;
      }
      .card h4 {
        font-size: 14px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Jobs {
    .available-jobs {
      li {
        padding: 10px;
      }
      .card {
        height: 350px;
      }
    }
    .team {
      .col-lg-3 {
        padding: 3px;
      }
      .card h4 {
        font-size: 14px;
        font-family: Cairo;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .Jobs {
    li {
      padding: 10px;
    }
    .card {
      height: 350px;
    }
  }
}
</style>

<script>
// import axios from "axios";

export default {
  name: "JobsComponent",
  // data() {
  //   return {
  //     jobs: [],
  //     imgs: "",
  //   };
  // },

  // // This Best Job
  // methods: {
  //   fetchJobs() {
  //     axios
  //       .get("http://localhost/thakaa-api/public/jobs")
  //       .then((response) => {
  //         this.jobs = response.data;
  //         // console.log(this.jobs);
  //       })
  //       .catch((error) => {
  //         console.error("Error Fetching Data:", error);
  //       });
  //   },
  //   fetchImgs() {
  //     axios
  //       .get("http://localhost/thakaa-api/public/job_images")
  //       .then((response) => {
  //         this.imgs = response.data;
  //         // console.log(this.imgs);
  //       })
  //       .catch((error) => {
  //         console.error("Error Fetching Data:", error);
  //       });
  //   },
  // },

  // mounted() {
  //   this.fetchJobs();
  //   this.fetchImgs();
  // },
};
</script>
