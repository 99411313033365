<template>
  <ProjectsComponent></ProjectsComponent>
</template>

<style lang="scss" scoped></style>

<script>
import ProjectsComponent from "@/components/projects/Projects.component.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "ProjectsView",
  components: {
    ProjectsComponent,
  },
  created() {
    this.$store.commit("setPage", "projects");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: "ذكاء | أعمالنا",
      meta: [
        {
          name: `description`,
          content: `نساعد الشركات لتطوير البرمجة الخاصة بهم والاستفادة من الاتجاهات الرقمية ،وتلبية إحتياجات السوق ، وبناء ميزة تنافسية`,
        },
        {
          name: `keywords`,
          content: `حلول تقنية متكاملة , التحليل ودراسة تجربة المستخدم,الإستضافة السحابية ,حلول الحماية والأمن السيبراني ,التسويق وتطوير الأعمال ,التدريب والتأهيل , websites , برمجة التطبيقات , تطبيقات الذكاء الاصطناعي  ,   تطبيقات لربح المال , تطبيقات جوجل, software `,
        },
      ],
    });
  },
};
</script>
