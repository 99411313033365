<template>
  <div class="container">
    <div class="Request-Jobs">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-sm-12 c-text">
            <div class="text">
              <h1>هل تريد الإلتحاق بنا؟</h1>
              <h4>
                تقدم إلى إحدى وظائفنا ، وأرسل سيرتك الذاتية واترك لنا رسالة. أو
                يمكنك إرسال بريد إلكتروني إلينا على
                <a href="mailto:hr@thaka.net"> <span>hr@thaka.net</span> </a>
              </h4>
              <div class="col-8">
                <div class="form">
                  <form action="">
                    <div class="mb-3">
                      <label for="name" class="form-label">الاسم</label>
                      <input class="form-control" id="name" />
                    </div>

                    <div class="mb-3">
                      <label for="email" class="form-label"
                        >البريد الإلكتروني</label
                      >
                      <input type="email" class="form-control" id="email" />
                    </div>

                    <div class="mb-3">
                      <label for="select" class="form-label"
                        >تقدم على وظيفة</label
                      >
                      <select name="" id="select" class="form-select">
                        <option value="" selected>
                          من فضلك اختر أحد الوظائف المتاحة
                        </option>
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                      </select>
                    </div>

                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                        >رسالتك</label
                      >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>

                    <div class="mb-3">
                      <label for="formFile" class="form-label"
                        >سيرتك الذاتية</label
                      >
                      <input class="form-control" type="file" id="formFile" />
                    </div>

                    <div class="col-12">
                      <button type="submit" class="btn">ارسال</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 c-image">
            <div class="photo">
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/images/contact-img.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.Request-Jobs {
  margin: 100px 0px 0px;

  .text {
    width: 100%;
    margin: 0px 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1 {
      color: #0c7abd;
      font-size: 32px;
      font-family: Cairo;
      font-weight: 700;
      line-height: 50px;
    }
    h2 {
      color: #12151c;
      font-size: 24px;
      font-family: Cairo;
      font-weight: 700;
    }
    h4 {
      color: #12151c;
      font-size: 16px;
      font-family: Cairo;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 30px;
      span {
        color: $main-color;
        font-weight: bold;
      }
    }
    .form {
      width: 100%;
      height: 100% !important;
      label {
        color: #8a8a8a;
        font-size: 16px;
        font-family: Cairo;
        font-weight: 400;
        margin-right: 10px;
      }
      textarea {
        height: 190px;
      }
      .btn {
        width: 30%;
        background-color: #00abad;
        color: white;
      }
      .form-select {
        background-position: left 0.75rem center;
        padding: 10px;
      }
    }
  }
  .photo {
    text-align: end;
    img {
      width: 80%;
      height: 500px;
      margin: auto;
      border-radius: 10px;
    }
  }
}

@media (max-width: 575.98px) {
  .Request-Jobs {
    padding: 10px;

    .row {
      .c-image {
        order: 1;
      }
      .c-text {
        order: 2;
      }
      .text {
        margin: 50px auto;
        .col-8 {
          width: 100%;
        }
        form {
          width: 100%;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Request-Jobs {
    padding: 10px;

    .row {
      .c-image {
        order: 1;
      }
      .c-text {
        order: 2;
      }
      .text {
        margin: 50px auto;
        .col-8 {
          width: 100%;
        }
        form {
          width: 100%;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .Request-Jobs {
    .col-8 {
      width: 100%;
    }
    img {
      width: 100% !important;
    }
  }
}
// }
// @media (min-width: 992px) and (max-width: 1199.98px) {
// }
</style>

<script>
export default {
  name: "RequestJobComponent",
};
</script>
