<template>
  <div class="container">
    <div class="ContactUs">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <div class="text">
              <h1>أطلب</h1>
              <h2>خدماتنا من هنا!</h2>
              <h4>يمكنك ملئ البيانات بسهولة</h4>
              <h4>لا تحب هذا؟</h4>
              <h4>
                يمكنك مراسلتنا عن طريق

                <a href="mailto:hr@thaka.net">
                  <span> البريد الإلكتروني </span>
                </a>
              </h4>
            </div>
          </div>
          <div class="col-4">
            <div class="form">
              <form action="">
                <!-- @submit.prevent="requestData"   -->
                <div class="mb-3">
                  <label for="name" class="form-label">الاسم</label>
                  <input class="form-control" id="name" />
                </div>

                <div class="mb-3">
                  <label for="email" class="form-label"
                    >البريد الإلكتروني</label
                  >
                  <input type="email" class="form-control" id="email" />
                </div>

                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label"
                    >رسالتك</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>

                <div class="col-12">
                  <button type="submit" class="btn">ارسال</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 last">
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3449.9004456145926!2d31.70840937555797!3d30.154262174868446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDA5JzE1LjMiTiAzMcKwNDInMzkuNSJF!5e0!3m2!1sen!2seg!4v1696333246711!5m2!1sen!2seg"
                width="100%"
                height="100%"
                style="border: 0"
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.ContactUs {
  padding: 100px 30px;
  background-color: #cceeef;
  margin: 100px 0px 50px;
  border-radius: 10px;
  .text {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h1 {
      color: #0c7abd;
      font-size: 32px;
      font-family: Cairo;
      font-weight: 700;
    }
    h2 {
      color: #12151c;
      font-size: 24px;
      font-family: Cairo;
      font-weight: 700;
    }
    h4 {
      color: #8a8a8a;
      font-size: 16px;
      font-family: Cairo;
      font-weight: 700;
      span {
        color: #00abad;
      }
    }
  }
  .form {
    width: 100%;
    height: 100% !important;
    label {
      color: #8a8a8a;
      font-size: 16px;
      font-family: Cairo;
      font-weight: 400;
      margin-right: 10px;
    }
    textarea {
      height: 190px;
    }
    .btn {
      width: 30%;
      background-color: #00abad;
      color: white;
    }
  }
  .map {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 575.98px) {
  .ContactUs {
    padding: 30px;
    height: 100%;
    margin: 90px 0px 25px;
    padding: 50px 0px;
    .row {
      display: block;
    }
    .col-4 {
      width: 100%;
    }
    .text {
      margin-bottom: 50px;
      height: 210px;
    }
    .form {
      margin-bottom: 50px;
      .btn {
        width: 40%;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ContactUs {
    padding: 50px 30px;
    .row {
      display: block;
    }
    .col-4 {
      width: 100%;
    }
    .text {
      margin-bottom: 50px;
      height: 210px;
    }
    .form {
      margin-bottom: 50px;
      .btn {
        width: 40%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ContactUs {
    padding: 50px 0px;
    .row {
      // display: block;
    }
    .col-4 {
      width: 50%;
    }
    &.last {
      width: 100% !important;
    }
    .text {
      margin-bottom: 50px;
      height: 210px;
    }
    .form {
      margin-bottom: 50px;
      .btn {
        width: 40%;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
</style>

<script>
export default {
  name: "ContactsUsComponent",

  // data() {
  //   return {};
  // },

  // async mounted() {
  //   await this.requestData();
  // },

  // methods: {
  //   async contact() {
  //     const requestData = {
  //       Headers: { "Content-Type": "application/json" },
  //       methods: "POST",
  //       body: JSON.stringify(this.requestForm),
  //     };
  //     await fetch("http://localhost/thakaa-api/public/jobs", requestData)
  //       .then((response) => response.json())
  //       // console.log(this.jobs);
  //       .then((data) => console.log(data));
  //     // .catch((error) => {
  //     //   console.error("Error Fetching Data:", error);
  //     // });
  //   },
  // },
};
</script>
