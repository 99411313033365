<template>
  <div id="app">
    <div class="navbar-bg">
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <div class="barnd">
            <router-link to="/">
              <img
                class="navbar-brand"
                src="../../assets/home/logo.png"
                alt="logo"
              />
            </router-link>
          </div>
          <button
            v-if="showTogglerIcon"
            class="navbar-toggler"
            type="button"
            @click="toggleButtons"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <button
            v-if="!showTogglerIcon"
            class="navbar-toggler btn-close"
            type="button"
            @click="toggleButtons"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation Close"
            data-bs-dismiss="offcanvas"
          >
            <!-- <span>&times;</span> -->
            <span class="navbar-close-icon"></span>
          </button>
          <!-- <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          > 
          <span class="navbar-toggler-icon"></span>
        </button>
      -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link">الرئيسية </router-link>
              </li>
              <!-- :class="
                this.$store.getters.getPage == 'home'
                  ? 'router-link-exact-active'
                  : ''
              " -->
              <li>
                <router-link to="/about-us" class="nav-link"
                  >من نحن</router-link
                >
              </li>

              <li>
                <router-link
                  to="/projects"
                  class="nav-link"
                  :class="{
                    'router-link-exact-active':
                      this.$store.getters.getPage === 'projects' ||
                      this.$store.getters.getPage === 'ThakaProject' ||
                      this.$store.getters.getPage === 'FDLProject',
                  }"
                >
                  أعمالنا
                </router-link>
              </li>
              <li>
                <router-link
                  to="/services"
                  class="nav-link"
                  :class="{
                    'router-link-exact-active':
                      this.$store.getters.getPage === 'services' ||
                      this.$store.getters.getPage === 'services-lap' ||
                      this.$store.getters.getPage === 'services-marketing' ||
                      this.$store.getters.getPage === 'services-training' ||
                      this.$store.getters.getPage === 'services-users' ||
                      this.$store.getters.getPage === 'services-solution' ||
                      this.$store.getters.getPage === 'services-anylsis' ||
                      this.$store.getters.getPage === 'services-hosting' ||
                      this.$store.getters.getPage === 'services-secuirty',
                  }"
                  >خدماتنا</router-link
                >
              </li>

              <li>
                <router-link to="/contact-us" class="nav-link"
                  >تواصل معنا</router-link
                >
              </li>

              <li>
                <router-link to="/jobs" class="nav-link">وظائف</router-link>
              </li>

              <li>
                <a class="nav-link">مدونة</a>
                <!-- <router-link to="" class="nav-link"> مدونة </router-link> -->
              </li>
            </ul>
            <div class="btn-lang col-sm-none" role="button">
              <button class="lang" aria-label="button">
                <span>EN</span>
                <img
                  class="lang-icon"
                  src="../../assets/home/world.svg"
                  alt="world-icon"
                />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.navbar-bg {
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  box-shadow: 0 2px 2px rgba(112, 174, 170, 0.2);
  height: auto;

  .navbar {
    padding: 10px 0px 0px;
    top: 0;
    justify-content: space-around;
    display: flex;
    .barnd {
      width: 110px;
      padding: 0px;
      margin: 0%;
      .navbar-brand {
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0%;
      }
    }
    ul li {
      margin: 0px 15px;
    }
    .nav-link {
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      color: #595959;
    }
    .router-link-exact-active {
      color: $main-color;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 3px;
        background: $main-color;
        right: 0%;
      }
      // border-bottom: 3px solid $main-color;
    }
    .barnd .router-link-exact-active {
      &::after {
        display: none;
      }
    }
    .navbar-nav {
      margin: auto;
    }
    .btn-lang {
      padding: 0px 0px;
      .lang {
        padding: 7px 10px;
        border-radius: 10px;
        background: none;
        border: 1px solid #ccc;
        height: 40px;
        color: #767676;

        .lang-icon {
          width: 16px;
          height: 20px;
          margin: 2px 5px 3px 0px;
        }
      }
    }
  }

  .btn-close {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    padding: 20px 25px;
    border-radius: 10px;
    border: 1px solid #595959;
  }
}

@media (max-width: 575.98px) {
  .navbar {
    width: 100%;
    padding: 0px;
  }
  .navbar-bg .navbar {
    justify-content: space-between;

    .router-link-exact-active {
      color: $main-color;
      border-bottom: 0px;
    }
  }
  .navbar-collapse {
    padding: 20px;
    border-bottom: 1px solid #d0d0d0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .navbar-bg .navbar {
    justify-content: space-between;
    .router-link-exact-active {
      color: $main-color;
      border-bottom: 0px;
    }
    .navbar-collapse {
      padding: 20px;
      border-bottom: 1px solid #d0d0d0;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-bg .navbar {
    justify-content: space-between;
    .router-link-exact-active {
      color: $main-color;
      border-bottom: 0px;
    }
    .navbar-collapse {
      padding: 20px;
      border-bottom: 1px solid #d0d0d0;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-bg .navbar .nav-link {
    font-size: 15px;
  }
}
@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      showTogglerIcon: true,
    };
  },

  methods: {
    toggleButtons() {
      this.showTogglerIcon = !this.showTogglerIcon;
    },
  },
};
</script>
