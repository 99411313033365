import { createRouter, createWebHashHistory } from "vue-router";
// import Vue from "vue";
// import App from "@/App.vue";

import Home from "@/views/Home.view.vue";
import AboutUs from "@/views/AboutUs.view.vue";
import ContactUs from "@/views/ContactUs.view.vue";
import Services from "@/views/Services.view.vue";
import Jobs from "@/views/Jobs.view.vue";
import RequestJob from "@/views/RequestJob.view.vue";
import ServicesLap from "@/views/ServicesLap.view.vue";
import ServicesMarketing from "@/views/ServicesMarketing.view.vue";
import ServicesTraining from "@/views/ServicesTraining.view.vue";
import ServicesUsers from "@/views/ServicesUsers.view.vue";
import ServicesAnylsis from "@/views/ServicesAnylsis.view.vue";
import ServicesSecuirty from "@/views/ServicesSecuirty.view.vue";
import ServicesHosting from "@/views/ServicesHosting.view.vue";
import ServicesSolution from "@/views/ServicesSolution.view.vue";
import Blogs from "@/views/Blogs.view.vue";
import Projects from "@/views/Projects.view.vue";
import ThakaProject from "@/views/ThakaProject.view.vue";
import FDLProject from "@/views/FDLProject.view.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },

  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/services-lap",
    name: "ServicesLap",
    component: ServicesLap,
  },
  {
    path: "/services-marketing",
    name: "ServicesMarketing",
    component: ServicesMarketing,
  },
  {
    path: "/services-training",
    name: "ServicesTraining",
    component: ServicesTraining,
  },
  {
    path: "/services-users",
    name: "ServicesUsers",
    component: ServicesUsers,
  },
  {
    path: "/services-solution",
    name: "ServicesSolution",
    component: ServicesSolution,
  },
  {
    path: "/services-anylsis",
    name: "ServicesAnylsis",
    component: ServicesAnylsis,
  },
  {
    path: "/services-hosting",
    name: "ServicesHosting",
    component: ServicesHosting,
  },
  {
    path: "/services-secuirty",
    name: "ServicesSecuirty",
    component: ServicesSecuirty,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/request",
    name: "RequestJob",
    component: RequestJob,
  },
  {
    path: "/blog",
    name: "Blogs",
    component: Blogs,
  },

  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    // children: [
    //   {
    //     path: "review-thaka",
    //     name: "ThakaProject",
    //     component: ThakaProject,
    //   },
    //   {
    //     path: "review-fdl",
    //     name: "FDLProject",
    //     component: FDLProject,
    //   },
    // ],
  },
  {
    path: "/review-thaka",
    name: "ThakaProject",
    component: ThakaProject,
  },
  {
    path: "/review-fdl",
    name: "FDLProject",
    component: FDLProject,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// router.beforeEach((to) => {
//   document.title = to.meta?.title ?? "ذكاء | الرئيسية";
// });
// new Vue({
//   render: (h) => h(App),
//   router,
// }).$mount("#app");
export default router;
