<template>
  <div class="review-our-projects">
    <div class="review-about-site">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="review-about-site-text">
                <h1>شركة ذكاء الدولة</h1>
                <h2>نبذة عن الموقع</h2>
                <p>
                  موقع إلكتروني هدفه تعريف بشركة ذكاء الدولية وماهي خدماتنا التي
                  نقدمها وكيف للعميل طلب خدمة معنا ، وايضا عرض الوظائف المتاحة
                  والمشاريع التي قمنا بها.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="review-about-site-image">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/laptop_thaka.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="review-about-work">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-6 col-xl-3">
              <div class="review-project-card">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/faild.svg"
                  alt="icon"
                />
                <div class="review-project-desc">
                  <h2>مجال التصميم</h2>

                  <p>بروفايل تعريفي بالشركة</p>
                </div>
              </div>
            </div>

            <div class="col-6 col-xl-3">
              <div class="review-project-card">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/sites.svg"
                  alt="icon"
                />
                <div class="review-project-desc">
                  <h2>المنصة</h2>
                  <p>ويب/ موبايل</p>
                </div>
              </div>
            </div>

            <div class="col-6 col-xl-3">
              <div class="review-project-card">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/date.svg"
                  alt="icon"
                />
                <div class="review-project-desc">
                  <h2>تاريخ الإنجاز</h2>
                  <p>1/12/2023</p>
                </div>
              </div>
            </div>

            <div class="col-6 col-xl-3">
              <div class="review-project-card">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/teams.svg"
                  alt="icon"
                />
                <div class="review-project-desc">
                  <h2>فريق العمل</h2>
                  <p>5 أعضاء</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="review-complated-projects">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-6 review-order-1">
              <div class="review-about-site-image">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/thakaa-3.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 review-order-2">
              <div class="review-about-site-text">
                <h3>ما قمنا بتسليمه</h3>
                <h2>تجربة مستخدم بواجهة مستخدم ابداعية</h2>
                <p>
                  بدأنا بمرحلة البحث والاستكشاف UX و بمجرد الانتهاء من المراجعة
                  النهائية ، بدأنا في إنشاء واجهة المستخدم لبوابة الويب ، ثم
                  قمنا بتنفيذ واجهة المستخدم حتى تتناسب مع جميع الأجهزة حتى أصغر
                  جهاز
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="review-complated-projects-2">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="review-about-site-text">
                <h3>ما قمنا بتسليمه</h3>
                <h2>شاشات متجاوبة حتى أصغر جهاز</h2>
                <p>
                  تم تصميم وبرمجة جميع شاشات المتجاوبة مع جميع الأجهزة حتى يسهل
                  على المستخدم تصفح موقع الشركة من أي جهاز يفضله
                </p>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="review-about-site-image">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/projects/thakaa-4.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="review-process-projects">
      <div class="container">
        <h2>عملية التطوير</h2>
        <p>
          استخدمنا طريقة منظمة ومرنة لإنشاء أفضل تجربة مستخدم لعملائنا . لقد
          بدأنا العملية من خلال فهم طريقة سير العمل والمستخدمين المختلفين الذين
          تم تصميم المنصة من أجلهم. ساعدنا هذا في فهم احتياج المستخدمين . قمنا
          بتصميم أول نموذج أولي . تم اختيار النموذج الأولي للتصميم وتم دمج
          الملاحظات في تطوير النظام النهائي. قمنا بتطوير بوابات سهلة الاستخدام
          لـلويب ولوحة تحكم قوية مع أدوات شاملة للمسؤولين لإدارة ومراقبة جميع
          جوانب النظام. قمنا بتطوير نظام الواجهة الخلفية باستخدام PHP Framework
          ، وكان اختيارنا لقاعدة البيانات هو MySQL.و قمنا بأنشاء جميع الشاشات
          متجاوبة مع كل الأجهزة حتى أصغر جهاز ، قبل إطلاق المشروع ، خضع لعملية
          اختبار صارمة مع مئات من حالات الاختبار لضمان جودة الأداء.
        </p>
      </div>
    </div>

    <div class="review-tools-work">
      <div class="container">
        <h2>التقنيات المستخدمة</h2>
        <p>
          قمنا باستخدام أحدث وافضل التقنيات البرمجية التي تسهل من عملية تجربة
          المستخدم وانشاء موقع سريع الاستجابة
        </p>
        <div class="icons">
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/illustrator.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/photoshop.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/figma.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/HTML.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/Css.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/JS.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/Vue.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/laravel.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/php.png"
            alt=""
          />
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/projects/SQL.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="project-link">
      <div class="container">
        <h2>رابط الموقع</h2>

        <div class="project-btn-link">
          <router-link to="/">
            <button>موقع شركة ذكاء الدولية</button>
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/projects/click.svg"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
@import "../../scss/review-projects.scss";
.review-our-projects {
  .review-about-work {
    margin-top: 0px !important;
  }
}
</style>

<script>
export default {
  name: "ThakaProjectComponent",
};
</script>
