<template>
  <div class="Services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <h1>الخدمات</h1>
            <p>
              نساعد الشركات لتطوير البرمجيات الخاصة بهم والاستفادة من الاتجاهات
              الرقمية ،وتلبية إحتياجات السوق ، وبناء ميزة تنافسية.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div uk-slider>
            <div
              class="uk-products uk-position-relative uk-visible-toggle"
              tabindex="-1"
            >
              <ul
                class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-grid"
              >
                <!-- <li
                  class="perant-product"
                  v-for="service in services"
                  :key="service.id"
                >
                  <router-link to="/services-lap" class="nav-link">
                    <div class="product">
                    
                      <img
                      loading="lazy"
          width="100%"
          height="100%"
                        :src="
                          'http://localhost/thakaa-api/public/storage/photos/' +
                          service.image
                        "
                        alt="image"
                      />

                      <h1>{{ service.title }}</h1>
                     </div>
                    <div class="text">
                      {{ service.description }}
                    </div>
                    
                  </router-link>
                </li> -->
                <li class="perant-product">
                  <router-link to="/services-lap" class="nav-link">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/fa-solid_laptop-code.svg"
                        alt="image"
                      />

                      <h1>حلول تقنية متكاملة</h1>
                    </div>

                    <div class="text">
                      ننشئ ونطور الحلول التقنية للشركات والمؤسسات في المراحل
                      المختلفة بداية من الإنشاء حتى الانطلاق بالإضافة للدعم
                      التقني والمتابعة المستمرة
                    </div>
                  </router-link>
                </li>
                <li class="perant-product">
                  <router-link to="/services-marketing" class="nav-link">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/uil_chart-line.svg"
                        alt="image"
                      />

                      <h1>تسويق وتطوير الأعمال</h1>
                    </div>
                    <div class="text center-r">
                      نوفر لك كافة وسائل التسويق الالكتروني ونعملُ لك عليها،
                      لتسويق أعمالك مُنتجاتك ونشر هويتك لأكبر قدر ممكن من
                      العملاء المحتملين.
                    </div>
                  </router-link>
                </li>

                <li class="perant-product">
                  <router-link to="/services-training" class="nav-link">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/icon-park-solid_every-user.svg"
                        alt="image"
                      />

                      <h1>تدريب وتأهيل</h1>
                    </div>
                    <div class="text center">
                      نهدف في ذكاء الدولية إلى تطوير أداء المؤسسات ورفع مهارة
                      الأفراد من خلال إعداد البرامج التدريبية التي توفر الأدوات
                      اللازمة لزيادة الخبرات والمهارات .
                    </div>
                  </router-link>
                </li>

                <li class="perant-product">
                  <router-link to="/services-users" class="nav-link">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/ph_users-three-fill.svg"
                        alt="image"
                      />

                      <h1>الدراسات والإستشارات</h1>
                    </div>
                    <div class="text last">
                      تواصل مع طاقمنا الاستشاري للحصول على خدمات استشارية في
                      المجالات التقنية والإدارية تدعم نمو أعمالك.
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>

            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
@import "https://cdn.jsdelivr.net/npm/uikit@3.17.0/dist/css/uikit.min.css";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
}

.Services {
  padding: 50px 0px 50px;
  .services-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 50px;
    border-radius: 8px;
    margin-top: 50px;
    h1 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
  }
  .uk-products {
    margin: 50px 0px 0px;
    align-items: center;
  }
  .perant-product {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin: 20px auto;
    overflow: visible;
    height: 100%;
    position: relative;
    padding: 0%;

    .text {
      display: none;
      width: max-content;
      padding: 20px;
      letter-spacing: 1px;
      font-weight: 400;
    }

    .product {
      box-shadow: 0px 2px 4px rgb(207 216 213);
      height: 300px;
      padding: 20px;
      width: 91%;
      margin: 0px auto;
      transition: 0.4s;
      transition-timing-function: ease-in;
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin: 0px 10px;
      }
      h1 {
        font-size: 20px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
      }
      &::before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 80%);
        visibility: hidden;
        border: 35px solid;
        border-color: #28649c transparent transparent transparent;
      }
    }
    &:hover {
      .product {
        transform: scale(1.1);
        background-color: $main-color-alt;
        border-radius: 15px;
        h1 {
          color: white;
        }
        cursor: pointer;
        img {
          filter: brightness(350);
        }
        &::before {
          visibility: visible;
          animation-delay: 7ms;
        }
      }

      .text {
        display: block;
        // width: 100%;
        margin-top: 50px !important;
        float: inline-start;
      }
      .text.last {
        float: inline-end !important;
      }
      .text.center,
      .text.center-r {
        width: 600px;
        float: inline-start;
        padding: 0;
      }
      .text.center-r {
        float: inline-end;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .Services {
    text-align: justify;
    padding: 50px 0px 50px;
    height: auto;
    .services-header {
      padding: 20px;
      line-height: 32px;
    }
    .uk-products {
      height: auto;
    }
    .perant-product {
      padding: 2%;
      .product {
        display: inline-block;
        padding: 50px 20px;
        height: auto;
        align-items: center;
        margin: 10px;
        width: 95%;
        img {
          width: 100%;
          margin: auto;
        }
        h1 {
          text-align: center;
          margin-top: 20px;
        }
      }
      .text {
        width: auto;
        padding: 20px;
      }
      .text.center,
      .text.center-r {
        width: auto !important;
        padding: 20px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Services {
    text-align: justify;
    padding: 50px 0px 50px;
    height: auto;
    .services-header {
      padding: 20px;
      line-height: 32px;
    }
    .uk-products {
      height: auto;
    }
    .perant-product {
      padding: 2%;
      .product {
        display: inline-block;
        padding: 50px 10px;
        height: 250px;
        align-items: center;
        margin: 0px 5px;
        width: 98%;
        img {
          width: 100%;
          margin: auto;
        }
        h1 {
          text-align: center;
          margin-top: 20px;
        }
      }
      .text {
        width: auto;
        padding: 20px;
      }
      .text.center,
      .text.center-r {
        width: auto !important;
        padding: 20px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .Services {
    .product {
      padding: 0px !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px !important;
      h1 {
        text-align: center;
      }
    }
  }
  .text {
    width: 400px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Services {
    .product {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px !important;
      h1 {
        text-align: center;
      }
    }
    .text {
      width: 400px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .Services {
    .product {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px !important;
      h1 {
        text-align: center;
      }
    }
    .text {
      width: 400px !important;
    }
  }
}
@media (min-width: 1400px) {
  .Services {
    // .perant-product {
    //   background-color: #ddd;
    // }
    .product {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px !important;
      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
// import axios from "axios";

export default {
  name: "ServicesComponent",
  data() {
    // return {
    //   currentPage: "/services", // Initialize with the default active page
    // };
  },
  methods: {
    // updateCurrentPage() {
    //   this.currentPage = this.$route.name || "";
    // },
    // isLinkActive(route) {
    //   return this.$route.matched.some((record) => record.path === route);
    // },
  },
  // mounted() {
  //   this.updateCurrentPage();
  // },
};
</script>
