<template>
  <div class="ServicesMarketing">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/services" class="nav-link"
                    >خدماتنا</router-link
                  >
                </li>
                <span> / </span>
                <li class="breadcrumb-item active">
                  <a> الإستشارات والدراسات </a>
                </li>
              </ol>
            </nav>

            <h1>الإستشارات والدراسات</h1>
            <p>
              تواصل مع طاقمنا الاستشاري للحصول على خدمات استشارية في المجالات
              التقنية والإدارية تدعم نمو أعمالك.
            </p>
            <router-link to="contact-us" class="btn"> احجز الان </router-link>
          </div>

          <div class="contain">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg-6 c-text">
                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>الاستشارات التقنية</h2>
                    </div>

                    <p class="desc">
                      نقدم الاستشارات في مجالات أنظمة الحوكمة وال ERP والمتاجر
                      الإلكترونية بالإضافة إلى الاستشارات التقنية في مجال
                      البرمجيات والتجارة الإلكترونية.
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>دراسات جدوى</h2>
                    </div>

                    <p class="desc">
                      لدينا فريق متخصص في إعداد دراسات الجدوى للتحقق من توفر
                      شروط ومعايير المشاريع مما يساعد على تقليل المخاطر وتقليل
                      التكاليف من خلال الخيارات المناسبة على المستوى التشغيلي.
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>إعداد الخطط الإستراتيجية</h2>
                    </div>

                    <p class="desc">
                      من خلال وضع خطط استراتيجية مناسبة ستتمكن من التحكم في رؤية
                      أعمالك وتحويل الأهداف بعيدة المدى إلى نتائج واقعية وتقليل
                      المخاطر المحتملة وبناء التكامل بين هيكل المنظمة ومواردها
                      مع استراتيجيتها مما يضمن عمل المؤسسة بأكبر كفاءة ممكنة.
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>إعداد الأنظمة واللوائح الداخلية</h2>
                    </div>

                    <p class="desc">
                      تقدم ذكاء خدمات إستشارية في إعداد وإعادة الهيكلة التنظيمية
                      والتوصيف الوظيفي للمؤسسات والشركات، كما نقدم خدمة
                      الاستشارات وإعداد اللوائح والأنظمة الداخلية المعنية في
                      تطوير الحوكمة المؤسسية.
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>التقييم والمتابعة</h2>
                    </div>

                    <p class="desc">
                      نعمل في شركة ذكاء على تقديم خدمات Third Party Monitoring
                      والتي تهدف من خلالها إلى متابعة وتقييم تنفيذ المشاريع
                      والأداء المؤسسي لرفع وتحسين جودة العمل.
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-6 c-img">
                  <div class="image">
                    <img
                      loading="lazy"
                      width="100%"
                      height="100%"
                      src="../../assets/services/2-4.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
  line-height: 32px;
}

.ServicesMarketing {
  padding: 100px 0px 50px;
  .services-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 30px;
    border-radius: 8px;

    h1 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
    span {
      color: #616368;
      padding: 5px;
      margin-top: -12px;
      font-weight: 100;
    }
    .breadcrumb-item {
      color: #00abad;
      font-size: 14px;
      font-family: Cairo;
      font-weight: 400;
    }
    .breadcrumb-item.active {
      color: #616368;
    }
    .btn {
      width: auto;
      padding: 10px 20px;
      background: linear-gradient(90deg, #00abad 0%, #28649c 92%);
      color: white;
      margin: 10px auto 0px;
    }
  }
  .contain {
    padding: 70px 0px 0px;
    .text {
      padding: 10px 0px;
      .header {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin: 10px 0px 10px 10px;
        }
        h2 {
          @include header-h3;
          margin: 5px 0px;
        }
      }
      .desc {
        @include header-p;
        color: #0c7abd;
        margin: 0%;
      }
    }
  }

  .image {
    height: 500px;
    width: 90%;
    margin: 0 auto 0 0;
    img {
      border-radius: 8px;
    }
  }
}

@media (max-width: 575.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px 0px;
    .image {
      margin-bottom: 20px;
      height: 100%;
      width: 100%;
    }
    .c-text {
      order: 2;
    }
    .c-img {
      order: 1;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px 0px;
    .image {
      margin-bottom: 20px;
      height: 100%;
      width: 100%;
    }
    .c-text {
      order: 2;
    }
    .c-img {
      order: 1;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px 0px;
    .image {
      margin-bottom: 20px;
      width: 100%;
    }
    .c-text {
      order: 2;
    }
    .c-img {
      order: 1;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "ServicesMarketingComponent",
};
</script>
