<template>
  <footer>
    <div class="container">
      <div class="content-footer">
        <div class="card">
          <ul>
            <li>
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/home/logo.png"
                alt="logo"
              />
            </li>
            <li>
              <br />
            </li>
            <li>
              <img
                class="icons-img"
                src="../../assets/home/fluent_location-28-filled.svg"
                alt="icon"
              />

              الحي الثاني - مدينة بدر - القاهرة
            </li>
            <li>
              <img
                class="icons-img"
                src="../../assets/home/carbon_phone-filled.svg"
                alt="icon"
              />
              <span dir="ltr"> +0155 807 5795 </span>
            </li>
            <li>
              <img
                class="icons-img"
                src="../../assets/home/ic_baseline-mail.svg"
                alt="icon"
              />

              <a href="mailto:hr@thaka.net">info@thaka.net </a>
            </li>
          </ul>
        </div>
        <div class="card">
          <ul>
            <li class="first">القائمة</li>
            <router-link to="/" class="nav-link">
              <li>الرئيسية</li>
            </router-link>
            <li>
              <router-link to="/about-us" class="nav-link">من نحن</router-link>
            </li>
            <li>
              <router-link to="/services" class="nav-link">الخدمات</router-link>
            </li>
            <!-- <li>
              <router-link to="/contact-us" class="nav-link">
                تواصل معنا
              </router-link>
            </li> -->
            <li>
              <router-link to="/jobs" class="nav-link">وظائف</router-link>
            </li>
            <!-- <li>
              <router-link to="/" class="nav-link"> مدونة </router-link>
            </li> -->
            <li>
              <router-link to="/projects" class="nav-link">
                أعمالنا
              </router-link>
            </li>
          </ul>
        </div>

        <div class="card">
          <ul>
            <li class="first">الشركات الصديقة</li>
            <a
              href="https://thakaholding.com/"
              target="_blank"
              class="nav-link"
            >
              <li>ذكاء القابضة</li>
            </a>
            <li>
              <a href="#" target="_blank" class="nav-link"> التميز </a>
            </li>
            <li>
              <a
                href="https://quduratech.com/"
                target="_blank"
                class="nav-link"
              >
                قدراتك
              </a>
            </li>
            <li>
              <a
                href="https://robotera.com.sa/"
                target="_blank"
                class="nav-link"
              >
                روبوتيرا
              </a>
            </li>
            <li>
              <a href="http://tatheerplus.com" target="_blank" class="nav-link">
                تأثير
              </a>
            </li>
          </ul>
        </div>

        <div class="card">
          <ul>
            <li class="first">الخدمات</li>
            <li>
              <router-link to="/services-lap">
                <img
                  class="icons-img"
                  src="../../assets/home/solar_code-square-bold.svg"
                  alt="icon"
                />
                حلول تقنية متكاملة
              </router-link>
            </li>
            <li>
              <router-link to="/services-marketing">
                <img
                  class="icons-img"
                  src="../../assets/home/solar_code-square-bold.svg"
                  alt="icon"
                />
                تسويق وتطوير الأعمال
              </router-link>
            </li>
            <li>
              <router-link to="/services-training">
                <img
                  class="icons-img"
                  src="../../assets/home/solar_code-square-bold.svg"
                  alt="icon"
                />
                تدريب وتأهيل
              </router-link>
            </li>
            <li>
              <router-link to="/services-users">
                <img
                  class="icons-img"
                  src="../../assets/home/solar_code-square-bold.svg"
                  alt="icon"
                />
                الدراسات والإستشارات
              </router-link>
            </li>
          </ul>
        </div>
        <div class="card">
          <ul>
            <li class="first">اخر الأخبار</li>
            <li>ادخل بريدك الإلكتروني ليصلك كل جديد</li>
            <li class="form">
              <input type="text" placeholder=" بريدك الإلكتروني" />
              <span
                ><img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/home/mingcute_send-fill.svg"
                  alt=""
              /></span>
            </li>
            <li class="icons">
              <img
                src="../../assets/home/typcn_social-facebook.svg"
                alt="icons"
              />
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/home/mdi_twitter.svg "
                alt="icons"
              />
              <img
                src="../../assets/home/basil_instagram-solid.svg "
                alt="icons"
              />
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/home/ri_linkedin-fill.svg"
                alt="icons"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p><span> &copy; </span> 2023 جميع الحقوق محفوظة</p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

footer {
  background-color: #12151c;
  width: 100%;
  height: auto;
  margin: 0px;

  .content-footer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    padding: 25px 0px !important;
    border-bottom: 1px solid #ddd;
    .card {
      background: none;
      border: none;

      ul {
        border: none;
        li {
          border: none;
          color: #ffffffc9;
          margin: 10px 0px;
          padding: 0px;
          font-size: 16px;
          font-weight: 200;
          img {
            width: 150px;
            height: 100px;
            margin: -20px 0px;
          }
          a {
            color: white;
          }
          img.icons-img {
            width: 20px;
            height: 20px;
            margin-top: -20px;
            margin-left: 8px;
          }
        }
        li.first {
          color: $main-color-alt;
          border-bottom: 4px solid $main-color-alt;
          width: max-content;
          font-weight: 700;
          padding: 5px 0px;
        }
        li.form {
          display: flex;

          input {
            width: 80%;
            height: 40px;
            padding: 20px;
            border-start-start-radius: 5px;
            border-end-start-radius: 5px;
            outline: none;
            border: none;
          }
          span {
            background-color: $main-color;
            height: 40px;
            margin-top: -1px;
            padding: 7px;
            border-end-end-radius: 8px;
            border-start-end-radius: 8px;
            width: 50px;
          }
          span img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .copyright {
    width: 100%;
    padding: 10px;
    text-align: center;
    color: white;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      padding: 0%;
      margin: 0%;
      font-size: 14px;
      font-weight: 100;
    }
    // span {
    //   margin: 0px 4px;
    //   font-size: 18px;
    //   height: 10px !important;
    // }
  }
  hr {
    width: 80%;
    margin: auto;
    background-color: #ddd;
    height: 2px;
  }
  ul {
    li {
      input {
        width: 250px;
        height: 40px;
        padding: 20px;
        border-radius: 3px;
        outline: none;
        border: none;
      }
    }
    li.icons {
      display: flex;
      width: 250px;
      img {
        width: 30px !important;
        margin: 0px 5px !important;
        height: 50px !important;
      }
    }
  }
}
@media (max-width: 575.98px) {
  footer {
    width: 100%;
    justify-content: center;
    margin: auto;
    padding: 20px;

    .content-footer {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

      ul {
        li.icons {
          margin: auto !important;
          // justify-content: center;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  footer {
    width: 100%;
    justify-content: center;
    margin: auto;
    .content-footer {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

      ul {
        li.icons {
          margin: auto !important;
          // justify-content: center;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  footer {
    .content-footer {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer {
    .content-footer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      gap: 10px;
      padding: 20px;
      .card ul li img.icons-img {
        width: 20px;
        height: 20px;
        margin-top: -20px;
        margin-left: 2px;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  footer {
    .content-footer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
      gap: 10px;
      padding: 50px;
    }
  }
}
@media (min-width: 1400px) {
  footer {
    .content-footer {
      display: grid;

      grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
      gap: 40px;
      padding: 50px;
    }
  }
}
</style>

<script>
export default {
  name: "LowerFooterComponent",
};
</script>
