<template>
  <section class="news">
    <div class="conatiner">
      <div class="row">
        <div class="col-12">
          <div class="news-text">
            <h2>اشترك ليصلك إشعار!</h2>
            <p>
              قم بالتسجيل بالنشرة البريدية لتلقي التحديثات والأخبار الجديدة.
            </p>
            <form>
              <input type="text" placeholder="ادخل بريدك الإلكتروني" />
              <button>
                <p>اشترك</p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

$color_1: white;
$background-color_1: #28649c;

.news {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: url("../../assets/home/Background\ blue.jpg");
  background-size: cover;
  background-repeat: round;
  text-align: center;
  .news-text {
    padding: 100px;
    line-height: 60px;
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 34px;
      color: $color_1;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      color: $color_1;
      padding: 7px;
    }
    form {
      display: flex;
      justify-content: center;
      input {
        width: 289px;
        height: 40px;
        padding: 20px;
        border-radius: 3px;
        outline: none;
        border: none;
        margin: 0px 10px;
      }
      button {
        width: 80px;
        height: 40px;
        padding: 0;
        border-radius: 4px;
        gap: 8px;
        background-color: $background-color_1;
        border: none;
        color: $color_1;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .news .news-text {
    padding: 100px 0px;
  }
}
</style>

<script>
export default {
  name: "NewsComponent",
};
</script>
