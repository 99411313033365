<template>
  <AboutUsComponent></AboutUsComponent>
</template>

<style lang="scss" scoped>
// .AboutUs {
//   width: 100%;
//   height: 100%;
//   background-color: rgb(190, 82, 132);
// }
</style>

<script>
import AboutUsComponent from "@/components/about-us/AboutUs.component.vue";

import { useHead } from "@vueuse/head";

export default {
  name: "AboutsUsView",
  components: {
    AboutUsComponent,
  },
  created() {
    this.$store.commit("setPage", "contact");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: "ذكاء | من نحن",
      meta: [
        {
          name: `description`,
          content: `
          نعمل في ذكاء على رفع مستوى الخدمات التقنية في العالم العربي لتكون ذكاء الدولية شريك مؤثر في مجال الخدمات التقنية والتطبيقات والذكاء الاصطناعي وتطوير الأعمال.
          `,
        },

        {
          name: `keywords`,
          content: `About , ذكاء`,
        },
      ],
    });
  },
};
</script>
