<template>
  <div class="AboutUs" id="about">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-header">
            <h1>من نحن</h1>
            <p>
              نعمل في ذكاء على رفع مستوى الخدمات التقنية المقدمة في العالم
              العربي لتكون ذكاء الدولية شريك مؤثر في مجال الخدمات التقنية
              والذكاء الاصطناعي وتطوير الأعمال داخل وخارج الوطن العربي.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="about-thakaa">
            <div class="about-img">
              <img
                src="../../assets/images/close-up-executives-sitting-table.jpg"
                alt=""
              />
            </div>
            <div class="about-text">
              <div class="welcom-thakaa">
                <h3>مرحبا بك في ذكاء الدولية</h3>
                <p>
                  نتطلع في ذكاء الدولية لتأسيس منظومة متكاملة تقدم خبرة أكثر من
                  15 عاما لتوفر لك كل الحلول التقنية والاستشارات لإدارة وأتمتة
                  وتطوير أعمالك، بداية من الاستشارات المالية واستشارات الأعمال
                  إلى التطبيقات والبرامج التي تحتاجها في تشغيل أعمالك لنساعدك في
                  تطوير أعمالك وزيادة الكفاءة التشغيلية لعملك.
                </p>
              </div>
              <div class="gools-thakaa">
                <div class="gools-header">
                  <img
                    loading="lazy"
                    width="100%"
                    height="100%"
                    src="../../assets/home/octicon_goal-16.svg"
                    alt=""
                  />
                  <h3>هدفنا</h3>
                </div>
                <p>
                  توفير حلول تقنية ونظم معلومات لتلبي احتياجات العملاء في كل
                  مراحل ومجالات العمل ليحصل عملاؤنا على نتائج أفضل ومعدلات نمو
                  أسرع.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="vision-msg">
            <div class="card">
              <div class="card-v-header">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/home/Frame-1.svg"
                  alt=""
                />
                <h2>رؤيتنا</h2>
              </div>
              <p>
                أن نكون سند في نمو ونجاح المؤسسات والشركات من خلال تقديم حلول
                متقنة بكفاءة عالية.
              </p>
            </div>

            <div class="card">
              <div class="card-v-header">
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  src="../../assets/home/Frame-2.svg"
                  alt=""
                />
                <h2>رسالتنا</h2>
              </div>
              <p>
                أن نكون الرواد في تقديم خدمات تقنية المعلومات بكافة مجالاتها ،
                متسلحين بحلول تقنية متكاملة تواكب أحدث تقنية العصر الحديث.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="rwo">
        <div class="col-12">
          <h3 class="added-head">استراتيجية تنفيذ المشاريع</h3>
        </div>
      </div>

      <div class="vision-msg">
        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame2.svg"
              alt=""
            />
            <h2>التخطيط</h2>
          </div>
          <p>
            نضع أهداف المشروع ونحدد المتطلبات الأساسية ثم نقوم بتحليل المستخدمين
            المستهدفين وتحديد المميزات والوظائف المطلوبة.
          </p>
        </div>

        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame1.svg"
              alt=""
            />
            <h2>التصميم</h2>
          </div>
          <p>
            نحدد أعضاء الفريق ونضع الميزانية المناسبة والمتطلبات الأولية لتنفيذ
            المشروع.
          </p>
        </div>

        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame6.svg"
              alt=""
            />
            <h2>التطوير</h2>
          </div>
          <p>
            يعمل فريق التطوير لدينا على تقسيم المشروع إلى وحدات صغيرة ومتجانسة
            وتنفيذها بطريقة تفاعلية وتجربتها بشكل متكرر.
          </p>
        </div>

        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame5.svg"
              alt=""
            />
            <h2>التقييم والتحسين</h2>
          </div>
          <p>
            نراجع العمل المنجز بانتظام ونقيمه عن طريق معرفة ردود فعل المستخدمين
            واقتراحات العملاء لتحسين الموقع أو التطبيق وتعديله.
          </p>
        </div>

        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame3.svg"
              alt=""
            />
            <h2>التسليم</h2>
          </div>
          <p>
            نقوم بتسليم المكونات المكتملة بشكل مستمر ومنتظم.ثم نضيف الميزات
            الجديدة ونحسن المنتج استنادًا إلى احتياجات العملاء.
          </p>
        </div>

        <div class="card">
          <div class="card-v-header">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src="../../assets/about-us/Frame4.svg"
              alt=""
            />
            <h2>المتابعة والتحسين المستمرين</h2>
          </div>
          <p>
            نراقب أداء الموقع أو التطبيق ونجمع الملاحظات والبيانات لتحسينه بشكل
            مستمر. كما نحدث المحتوى ونصلح الأخطاء ونضمن توفير تجربة مستخدم
            محسنة.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
}
@mixin header-img {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
.AboutUs {
  height: auto;
  padding: 50px 0px;
  .about-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 50px;
    border-radius: 8px;
    margin-top: 60px;

    h1 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
  }
  .about-thakaa {
    display: flex;

    padding: 50px 0px;
    .about-img {
      width: 50%;
      height: auto;
      order: 2;
      text-align: end;
      img {
        width: 90%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .about-text {
      width: 50%;
      height: 100%;
      order: 1;

      h3 {
        @include header-h3;
      }
      p {
        @include header-p;
        line-height: 30px;
      }
      .gools-thakaa {
        padding: 20px;
        .gools-header {
          display: flex;
          img {
            @include header-img;
          }
        }
      }
    }
  }
  .vision-msg {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .card {
      width: 49%;
      min-height: 100%;
      padding: 50px;
      padding: 20px 10px;
      border: 1px solid #ddd;
      border-radius: 20px;
      margin: 15px auto;
      .card-v-header {
        display: flex;
        img {
          @include header-img;
        }
        h2 {
          @include header-h3;
        }
      }
      p {
        @include header-p;
      }
    }
  }
  .added-head {
    @include header-h3;
    margin: 70px 25px;
  }
}

@media (max-width: 575.98px) {
  .AboutUs {
    text-align: justify;
    padding: 50px 0px;
    .about-header {
      padding: 20px;
      line-height: 32px;
    }
    .about-thakaa {
      display: inline-block;
      padding: 50px 0px 0px;
      .about-img {
        width: 100%;
        text-align: center;
        img {
          width: 100%;
        }
      }
      .about-text {
        width: 100%;
        margin: 10px 0px;
        h3 {
          margin-top: 30px;
        }
        .gools-thakaa .gools-header img {
          margin: 20px 0px 20px 15px;
        }
        .gools-thakaa p {
          text-align: justify;
        }
      }
    }
    .vision-msg {
      display: inline-block;
      width: 100%;
      height: auto;
      .card {
        margin: 20px 0px;
        text-align: justify;
        width: 100%;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .AboutUs {
    text-align: justify;
    padding: 50px 0px;
    .about-header {
      padding: 50px 5px;
    }
    .about-thakaa {
      display: inline-block;
      padding: 50px 0px 0px;
      .about-img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .about-text {
        width: 100%;
        margin: 10px 0px;
        h3 {
          margin-top: 30px;
        }
        .gools-thakaa .gools-header img {
          margin: 20px 0px 20px 15px;
        }
        .gools-thakaa p {
          text-align: justify;
        }
      }
    }
    .vision-msg {
      display: inline-block;
      width: 100%;
      height: auto;
      .card {
        margin: 20px 0px;
        text-align: justify;
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .AboutUs {
    .about-thakaa .about-text {
      p {
        letter-spacing: 0.2px;
        line-height: 26px;
      }
      .gools-thakaa {
        padding: 0px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "AboutUsComponent",
};
</script>
