<template>
  <div class="container">
    <div class="projects">
      <div class="col-12 col-md-6 col-xl-4">
        <router-link :to="{ name: 'ThakaProject' }">
          <!-- <router-link to="/review-thaka"> -->
          <div class="card">
            <div class="project-imgs">
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/projects/cover-2.png"
                alt="Image 3"
              />
            </div>

            <h2 class="project-header">موقع شركة ذكاء الدولية</h2>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <router-link to="/review-fdl">
          <div class="card">
            <div class="project-imgs">
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/projects/cover-1.png"
                alt="Image 3"
              />
            </div>

            <h2 class="project-header">دوري بلو</h2>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.router-link-exact-active {
  color: $main-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    width: 100%;
    height: 3px;
    background: $main-color;
    right: 0%;
  }
  // border-bottom: 3px solid $main-color;
}
.projects {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 100px 0px 50px;
  margin: 50px 0px;

  .card {
    border: 1px solid $main-color;
    border-radius: 5px;
    margin: 20px;
    transition: all 0.5s ease-in-out;
    .project-imgs {
      width: 100%;
      height: 350px !important;
      img {
        width: 100%;
        height: 100% !important;
      }
    }
    .project-header {
      padding: 20px;
      border-top: 1px solid $main-color;
      margin: 0%;
      color: $main-color;
      font-size: 18px;
      font-family: Cairo;
      font-weight: 700;
    }
    &:hover {
      transition: $main-transition;
      cursor: pointer;
      .project-header {
        background: linear-gradient(90deg, #00abad 0%, #28649c 92%);
        color: white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .projects {
    padding: 50px 0px 0px;
  }
}
</style>

<script>
export default {
  name: "ProjectsComponent",
  data() {
    return {
      currentPage: "/projects", // Initialize with the default active page
    };
  },
  methods: {
    updateCurrentPage() {
      // Get the current route name and update currentPage
      this.currentPage = this.$route.name || "";
    },
    isLinkActive(route) {
      // Check if the current route or any of its matched routes match the provided route
      return this.$route.matched.some((record) => record.path === route);
    },
  },
  mounted() {
    // Update currentPage when the component is mounted
    this.updateCurrentPage();
  },
};
</script>
