<template>
  <div class="home">
    <h1 style="display: none">
      ذكاء الدولية توفر لك . تطبيقات . البرمجه . مواقع
    </h1>
    <h2 style="display: none">
      نقدم لك جميع خدمات المواقع الإلكترونية وتطبيقات الموبايل وبرامج إدارة
      المؤسسات وكل الحلول التقنية الذكية التي تحتاجها في إنشاء وتطوير أعمالك
      لتنقل أعمالك للمستقبل وتواكب تقنيات الذكاء الاصطناعي.
    </h2>
    <LandingComponent></LandingComponent>
    <AboutComponent></AboutComponent>
    <CompetitionComponent></CompetitionComponent>
    <OurServicesComponent></OurServicesComponent>
    <OurProductsComponent></OurProductsComponent>
    <JobComponent></JobComponent>
    <NewsComponent></NewsComponent>
  </div>
</template>

<script>
import LandingComponent from "@/components/home/Landing.component.vue";
import AboutComponent from "@/components/home/About.component.vue";
import CompetitionComponent from "@/components/home/Competition.component.vue";
import OurServicesComponent from "@/components/home/OurServices.component.vue";
import OurProductsComponent from "@/components/home/OurProducts.component.vue";
import JobComponent from "@/components/home/Job.component.vue";
import NewsComponent from "@/components/home/News.component.vue";
import { useHead } from "@vueuse/head";
export default {
  name: "HomeView",
  components: {
    LandingComponent,
    AboutComponent,
    CompetitionComponent,
    OurServicesComponent,
    OurProductsComponent,
    JobComponent,
    NewsComponent,
  },

  created() {
    this.$store.commit("setPage", "contact");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },

  setup() {
    useHead({
      title: "ذكاء | الرئيسية",
      meta: [
        {
          name: `description`,
          content: `خدمات المواقع الإلكترونية وتطبيقات الموبايل وبرامج إدارة المؤسسات وكل الحلول التقنية الذكية لإنشاء وتطوير أعمالك لتنقل أعمالك للمستقبل وتواكب تقنيات الذكاء الاصطناعي`,
        },
        {
          name: `keywords`,
          content: `thaka , programming , applications , software , websites , برمجة التطبيقات  ,  ذكاء , تطبيقات الذكاء الاصطناعي  , تطبيقات لربح المال , تطبيقات جوجل`,
        },
      ],
    });
  },
};
</script>
