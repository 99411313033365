<template>
  <div class="Jobs">
    <JobsComponent></JobsComponent>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import JobsComponent from "@/components/job/Jobs.component.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "JobsView",
  components: {
    JobsComponent,
  },
  created() {
    this.$store.commit("setPage", "JobsView");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: "ذكاء | وظائف",
      meta: [
        {
          name: `description`,
          content: ` البحث عن وظائف في ذكاء من هنا`,
        },

        {
          name: `keywords`,
          content: `Contact  , Job, fullstack developer ,frontend, backend developer, E-content developer, UI/UX Designer,وظيفف مبرمجين
          , contact us , تواصل معنا , ذكاء`,
        },
      ],
    });
  },
};
</script>
