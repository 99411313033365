<template>
  <ServicesAnaylsisComponent></ServicesAnaylsisComponent>
</template>

<style lang="scss" scoped></style>

<script>
import ServicesAnaylsisComponent from "@/components/services/ServicesAnaylsis.component.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "ServicesAnaylsisView",
  components: {
    ServicesAnaylsisComponent,
  },
  created() {
    this.$store.commit("setPage", "services");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: " ذكاء  | التحليل ودراسة تجربة المستخدم",
      meta: [
        {
          name: `description`,
          content: `نساعد الشركات لتطوير البرمجة الخاصة بهم والاستفادة من الاتجاهات الرقمية ،وتلبية إحتياجات السوق ، وبناء ميزة تنافسية`,
        },
        {
          name: `keywords`,
          content: `حلول تقنية متكاملة , التحليل ودراسة تجربة المستخدم,الإستضافة السحابية ,حلول الحماية والأمن السيبراني ,التسويق وتطوير الأعمال ,التدريب والتأهيل , websites , برمجة التطبيقات , تطبيقات الذكاء الاصطناعي  ,   تطبيقات لربح المال , تطبيقات جوجل, software `,
        },
      ],
    });
  },
};
</script>
