<template>
  <div class="Serviceslap">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/services" class="nav-link"
                    >خدماتنا</router-link
                  >
                </li>
                <span> / </span>
                <li class="breadcrumb-item active">
                  <a> حلول تقنية متكاملة </a>
                </li>
              </ol>
            </nav>

            <h1>حلول تقنية متكاملة</h1>
            <p>
              ننشئ ونطور الحلول التقنية للشركات والمؤسسات في المراحل المختلفة
              بداية من الإنشاء حتى الانطلاق بالإضافة للدعم التقني والمتابعة
              المستمرة نوفر خدمات التخطيط وتحليل النظام والتطوير والاختبار
              وإنشاء التطبيقات المتوافقة مع أنظمة التشغيل المختلفة IOS ,
              Android,Windows, LINUX
            </p>
            <router-link to="contact-us" class="btn"> احجز الان </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div uk-slider>
            <div
              class="uk-products uk-position-relative uk-visible-toggle"
              tabindex="-1"
            >
              <ul
                class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
              >
                <router-link to="/services-solution" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/home/icon-park-outline_code-laptop.svg"
                        alt="image"
                      />
                      <h1>حلول تطوير البرمجيات</h1>

                      <span>
                        نسعى إلى حلول الويب المبتكرة لتصميم المواقع الإلكترونية
                        بشكل فعال ومتجاوب مع جميع الأجهزة.
                      </span>
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-anylsis" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/icon-park-outline_collect-laptop.svg"
                        alt="image"
                      />

                      <h1>التحليل ودراسة تجربة المستخدم</h1>

                      <span
                        >تصميم واجهة المستخدم وتجربة المستخدم وإنشاء النماذج
                        الأولية للتحقق من صحة الأفكار ونقل المنتجات الرقمية إلى
                        السوق بثقة.
                      </span>
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-hosting" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/uil_laptop-cloud.svg"
                        alt="image"
                      />

                      <h1>الإستضافة السحابية</h1>
                      <span>
                        تقدم شركة ذكاء الدولية بتقديم خدمات تأجير السيرفرات
                        الخاصة بمواصفات عالية تلبي جميع احتياجات المؤسسات.
                      </span>
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-secuirty" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/fluent_laptop-shield-20-regular.svg"
                        alt="image"
                      />

                      <h1>حلول الحماية والأمن السيبراني</h1>
                      <span>
                        تمتع بحماية قوية ضد الاختراقات والمخترقين الذين يحاولون
                        تعطيل أعمالك مع حلول ذكاء للأمن السيبراني.
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>

            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
@import "https://cdn.jsdelivr.net/npm/uikit@3.17.0/dist/css/uikit.min.css";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
}

.Serviceslap {
  // padding: 0px 0px 50px;
  .services-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 30px;
    border-radius: 8px;
    margin-top: 100px;
    h1 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
    span {
      color: #616368;
      padding: 5px;
      margin-top: -12px;
      font-weight: 100;
    }
    .breadcrumb-item {
      color: #00abad;
      font-size: 14px;
      font-family: Cairo;
      font-weight: 400;
    }
    .breadcrumb-item.active {
      color: #616368;
    }
    .btn {
      width: auto;
      padding: 10px 20px;
      background: linear-gradient(90deg, #00abad 0%, #28649c 92%);
      color: white;
      margin: 10px auto 0px;
    }
  }
  .uk-products {
    height: auto;
    padding: 100px 0px;
  }
  .perant-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    overflow: visible;
    height: 100%;
    position: relative;
    border-radius: 8px;
    .text {
      display: none;
      width: max-content;
      padding: 20px;
      letter-spacing: 1px;
      font-weight: 400;
    }
    text-align: center;

    .product {
      display: flex;
      gap: 0;
      justify-content: center;
      padding: 10px;
      margin: 15px 8px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
      align-items: center;
      height: 300px;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      width: 90%;
      img {
        width: 80px;
        height: 80px;
        margin: 0px 10px;
      }
      h1 {
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
      }
      span {
        width: 100%;
        height: 90%;
        background-color: white;
        position: absolute;
        top: 299px;
        left: -70px;
        display: flex;
        text-align: center;
        align-items: center;
        padding: 30px;
        z-index: -1;
        transition: $main-transition ease-in-out;
        font-size: 22px;
        font-weight: 300;
        line-height: 30px;
      }
      &:hover {
        span {
          z-index: 1;
          cursor: pointer;
          top: 10px;
          left: 0;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .Serviceslap {
    text-align: justify;
    padding: 10px 0px 50px;
    height: auto;
    .services-header {
      padding: 20px;
      line-height: 32px;
      span {
        margin-top: -5px;
      }
    }
    .uk-products {
      height: auto;
      padding: 50px 0px 0px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Serviceslap {
    text-align: justify;
    padding: 20px 0px 50px;
    height: auto;
    .perant-product .product {
      flex-direction: column;
    }
    .services-header {
      padding: 20px;
      line-height: 32px;
      span {
        margin-top: -5px;
      }
    }
    .uk-products {
      height: auto;
      padding: 50px 0px 0px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "ServicesLapComponent",
};
</script>
