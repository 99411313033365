<template>
  <FDLProjectComponent></FDLProjectComponent>
</template>

<style lang="scss" scoped></style>

<script>
import FDLProjectComponent from "@/components/projects/FDLProject.component.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "FDLProject",
  components: {
    FDLProjectComponent,
  },
  created() {
    this.$store.commit("setPage", "projects");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: " ذكاء | نبذة عن دورى يلو",
      meta: [
        {
          name: `description`,
          content: `نساعد الشركات لتطوير البرمجة الخاصة بهم والاستفادة من الاتجاهات الرقمية ،وتلبية إحتياجات السوق ، وبناء ميزة تنافسية`,
        },
        {
          name: `keywords`,
          content: `حلول تقنية متكاملة , التحليل ودراسة تجربة المستخدم,الإستضافة السحابية ,حلول الحماية والأمن السيبراني ,التسويق وتطوير الأعمال ,التدريب والتأهيل , websites , برمجة التطبيقات , تطبيقات الذكاء الاصطناعي  ,   تطبيقات لربح المال , تطبيقات جوجل, software `,
        },
      ],
    });
  },
};
</script>
