<template>
  <div class="container">
    <div class="blog"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.blog {
  padding: 100px 30px;
  margin: 50px 0px;
}
</style>

<script>
export default {
  name: "BlogsComponent",
};
</script>
