<template>
  <section class="Competitions">
    <div class="container">
      <h2 class="head-page">كيف نعمل؟</h2>

      <div
        id="carouselExampleCaptions"
        class="carousel carousel-fade"
        data-bs-ride="carousel"
        uk-autoplay="true"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="Competitions-text">
              <h2>1. الحصول على المعلومات</h2>

              <p class="wow animate__animated animate__fadeInUp">
                نحن لا ننظر فقط إلى البيانات. نحن نبحث عن المعلومات. المعلومات
                التي تقود التحليل والرؤى ، والتي تمكننا من اكتساب فهم أفضل
                للأفراد والصناعة والأسواق ، وبالتالي تساعدنا على رسم طريق أفضل
                لتحقيق نتائج أفضل.
              </p>
            </div>

            <div class="Competitions-img">
              <img
                src="../../assets/home/image-20.png"
                class="d-block w-100 animate__animated animate__fadeInBottomRight"
                alt="image"
              />
            </div>
          </div>

          <div class="carousel-item">
            <div class="Competitions-text">
              <h2>2. التفكير في التصميم</h2>

              <p class="wow animate__animated animate__fadeInUp">
                يساعدنا العصف الذهني في التفكير التصميمي على تحدي الافتراضات
                والتوصل إلى أفكار مبتكرة ومبدعة.
              </p>
            </div>

            <div class="Competitions-img">
              <img
                src="../../assets/home/image-22.png"
                class="d-block w-100 animate__animated animate__fadeInBottomRight"
                alt="image"
              />
            </div>
          </div>

          <div class="carousel-item">
            <div class="Competitions-text">
              <h2>3. الابداع اولا</h2>
              <p class="wow animate__animated animate__fadeInUp">
                نضع حلول التحول الفكرة الإبداعية لدينا في مركز المحتوى الخاص بنا
                ، بغض النظر عن التنسيق أو الوسائط وهذا من أجل الحصول على أفكار
                إبداعية في البداية .
              </p>
            </div>

            <div class="Competitions-img">
              <img
                src="../../assets/home/image-21.png"
                class="d-block w-100 animate__animated animate__fadeInBottomRight"
                alt="image"
              />
            </div>
          </div>

          <div class="carousel-item">
            <div class="Competitions-text">
              <h2>4. البرمجه و التطوير</h2>
              <p class="wow animate__animated animate__fadeIn">
                نحن لا نكتفي بأخذ موجز وتقديمه. نحن نعمل مع عملائنا لإيجاد حلول
                برمجية متطورة قائمة على المحتوى لأهداف أو قضايا العمل. نحن نعمل
                كشركاء ، وفي معظم الحالات ، تكون حلولنا عصرية ومنتجة.
              </p>
            </div>

            <div class="Competitions-img">
              <img
                src="../../assets/home/image-19.png"
                class="d-block w-100 animate__animated animate__fadeInBottomRight"
                alt="image"
              />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-next"
          name="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src="../../assets/home/arrow.svg"
            alt="image"
          />
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.Competitions {
  margin: 0px;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: url("../../assets/home/Background\ blue.jpg");
  background-size: cover;
  background-repeat: round;
  position: relative;
  h2.head-page {
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    color: $main-color-alt;
    position: absolute;
    top: 95px;
    right: 127px;
  }
  .carousel-inner {
    padding: 20px;
    position: relative;
    .carousel-item {
      display: flex;
      align-items: center;
    }
  }
  .Competitions-img {
    // padding: 50px 0px;
    width: 40%;
    height: 100%;
    margin-left: 40px;
    img {
      width: 400px;
      height: 400px;
      animation-duration: 2s;
      margin: 0px auto 0px 0px;
    }
  }

  .Competitions-text {
    padding: 100px 0px;
    width: 65%;
    height: 100%;
    line-height: 2px;
    animation-duration: 1s;

    h2 {
      color: white;
      line-height: 70px;
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: 700;
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      color: white;
      width: 80%;
      letter-spacing: 1px;
      text-align: justify;
    }
  }
}
.carousel-control-next {
  position: absolute;
  width: fit-content;
  right: 95%;
}
@media (max-width: 575.98px) {
  .Competitions {
    .carousel-control-next {
      position: absolute;
      width: -moz-fit-content;
      width: fit-content;
      right: 80%;
      transform: translateY(40%);
    }
    background-repeat: space;
    h2.head-page {
      top: 5%;
      right: 0%;
      width: 100%;
      margin: auto;
      text-align: center;
    }
    .carousel-inner {
      padding: 10px;
      .carousel-item {
        display: inline-block;
      }
    }
    .Competitions-img {
      padding: 0px 0px 40px;
      margin-left: 10px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .Competitions-text {
      width: 100%;
      padding: 60px 0px 0px;
      text-align: center;
      h2 {
        font-size: 20px;
      }
      p {
        width: 100%;
        letter-spacing: 0.5px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Competitions {
    .carousel-control-next {
      position: absolute;
      width: -moz-fit-content;
      width: fit-content;
      right: 90%;
      width: 100px;
      transform: translateY(40%);
    }
    background-repeat: space;
    h2.head-page {
      top: 5%;
      right: 0%;
      width: 100%;
      margin: auto;
      text-align: center;
    }
    .carousel-inner {
      padding: 10px;
      .carousel-item {
        display: inline-block;
      }
    }
    .Competitions-img {
      padding: 0px 20px 40px;
      margin-left: 10px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .Competitions-text {
      width: 100%;
      padding: 60px 0px 0px;
      text-align: center;
      h2 {
        font-size: 20px;
      }
      p {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .Competitions {
    align-items: center;

    .carousel-control-next {
      width: 70px;
    }
    .carousel-inner {
      padding: 10px;
    }
    h2 {
      right: 65px;
      top: 80px;
    }
    .Competitions-img {
      padding: 80px 0px;
      margin-left: 10px;
      width: 80%;
      img {
        width: 100%;
      }
    }
    .Competitions-text {
      padding: 100px 00px;
      line-height: 20px;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 18px;
        line-height: 26px;
        text-align: justify;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Competitions {
    .carousel-inner {
      padding: 10px;
    }
    h2 {
      right: 95px;
      top: 80px;
    }
    .Competitions-img {
      padding: 40px 20px;
      margin-left: 10px;
      img {
        width: 150px;
      }
    }
    .Competitions-text {
      padding: 100px 20px;
      p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .Competitions {
    .Competitions-img {
      img {
        width: 500px !important;
      }
    }
  }
}
</style>

<script>
export default {
  name: "CompetitionComponent",
};
</script>
