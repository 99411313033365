<template>
  <div class="Serviceslap">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <h2>حلول تقنية</h2>
            <p>
              يمكننا مساعدتك في تخطيط وتصميم وتطوير أي نوع من المنتجات الرقمية.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div uk-slider>
            <div
              class="uk-products uk-position-relative uk-visible-toggle"
              tabindex="-1"
            >
              <ul
                class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
              >
                <router-link to="/services-solution" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/home/icon-park-outline_code-laptop.svg"
                        alt="image"
                      />
                      <h2>حلول تطوير البرمجيات</h2>

                      <span
                        >نسعى إلى حلول الويب المبتكرة لتصميم المواقع الإلكترونية
                        بشكل فعال ومتجاوب مع جميع الأجهزة.</span
                      >
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-anylsis" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/icon-park-outline_collect-laptop.svg"
                        alt="image"
                      />

                      <h2>التحليل ودراسة تجربة المستخدم</h2>
                      <span>
                        تصميم واجهة المستخدم وتجربة المستخدم وإنشاء النماذج
                        الأولية للتحقق من صحة الأفكار ونقل المنتجات الرقمية إلى
                        السوق بثقة.
                      </span>
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-hosting" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/uil_laptop-cloud.svg"
                        alt="image"
                      />

                      <h2>الإستضافة السحابية</h2>
                      <span>
                        تقدم شركة ذكاء الدولية بتقديم خدمات تأجير السيرفرات
                        الخاصة بمواصفات عالية تلبي جميع احتياجات المؤسسات.
                      </span>
                    </div>
                  </li>
                </router-link>

                <router-link to="/services-secuirty" class="nav-link">
                  <li class="perant-product">
                    <div class="product">
                      <img
                        loading="lazy"
                        width="100%"
                        height="100%"
                        src="../../assets/services/fluent_laptop-shield-20-regular.svg"
                        alt="image"
                      />

                      <h2>حلول الحماية والأمن السيبراني</h2>
                      <span>
                        تمتع بحماية قوية ضد الاختراقات والمخترقين الذين يحاولون
                        تعطيل أعمالك مع حلول ذكاء للأمن السيبراني.
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>

            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
@import "https://cdn.jsdelivr.net/npm/uikit@3.17.0/dist/css/uikit.min.css";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
}

.Serviceslap {
  padding: 100px 0px;
  background-color: #cceeef;
  .services-header {
    font-size: 24px;
    font-weight: 700;
    padding: 0px 0px 30px;
    h2 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
  }

  .perant-product {
    display: flex;
    flex-direction: column;
    gap: 50px !important;
    overflow: visible;
    height: 100%;
    position: relative;
    align-items: center;
    // margin: 0px -10px !;
    padding: 0;
    width: 100%;

    .text {
      display: none;
      width: max-content;
      padding: 20px;
      letter-spacing: 1px;
      font-weight: 400;
    }

    .product {
      display: flex;
      justify-content: center;
      padding: 10px;
      margin: 15px 8px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
      align-items: center;

      height: 300px;
      position: relative;
      overflow: hidden;
      width: 90%;
      border-radius: 10px !important;
      background-color: white;
      img {
        width: 80px;
        height: 80px;
        margin: 0px 10px;
      }
      h2 {
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
        // text-align: center;
      }
      span {
        width: 100%;
        height: 90%;
        background-color: white;
        position: absolute;
        top: 299px;
        left: -70px;
        display: flex;
        text-align: center;
        align-items: center;
        padding: 30px;
        z-index: -1;
        transition: $main-transition ease-in-out;
        font-size: 22px;
        font-weight: 300;
        line-height: 30px;
      }
      &:hover {
        span {
          z-index: 1;
          cursor: pointer;
          top: 10px;
          left: 0;
        }
      }
    }
    // &:first-child {
    //   align-items: flex-start;
    //   background-color: #12151c;
    // }
    // &:last-child {
    //   align-items: flex-end;
    //   background-color: #3e6cd7;
    // }
  }
}

@media (max-width: 575.98px) {
  .Serviceslap {
    text-align: justify;
    padding: 70px 0px 50px;
    height: auto;
    .services-header {
      padding: 20px;
      line-height: 32px;
      span {
        margin-top: -5px;
      }
    }
    .uk-products {
      height: auto;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .Serviceslap {
    text-align: justify;
    padding: 50px 0px;
    height: auto;
    .perant-product .product {
      width: 95% !important;
      padding: 0%;
      h2 {
        font-size: 20px;
        font-weight: 800;
        line-height: 34px;
        text-align: right;
      }
    }
    .services-header {
      padding: 20px;
      line-height: 32px;
      span {
        margin-top: -5px;
      }
    }
    .uk-products {
      height: auto;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .Serviceslap .perant-product .product {
    width: 95% !important;
    h2 {
      font-size: 22px;
      font-weight: 800;
      line-height: 34px;
      text-align: right;
    }
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .Serviceslap .perant-product .product {
    width: 95% !important;
  }
  .Serviceslap .perant-product .product h2 {
    font-size: 18px;
    font-weight: 800;
    line-height: 34px;
    text-align: start;
  }
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "ServicesLapComponent",
};
</script>
