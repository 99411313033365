<template>
  <div class="header">
    <div class="overly">
      <div
        id="carouselExampleInterval"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item" data-bs-interval="4000">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              class="d-block"
              alt="header-image"
              src="../../assets/home/Pic1.jpg"
            />
          </div>
          <div class="carousel-item active" data-bs-interval="4000">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              class="d-block"
              alt="header-image"
              src="../../assets/home/Pic2.jpg"
            />
          </div>
          <div class="carousel-item" data-bs-interval="4000">
            <img
              loading="lazy"
              width="100%"
              height="100%"
              class="d-block"
              alt="header-image"
              src="../../assets/home/Pic3.jpg"
            />
          </div>

          <div class="carousel-caption">
            <div class="container">
              <div class="desc">
                <h2 class="wow animate__animated animate__bounce">
                  ذكاء الدولية توفر لك …..
                </h2>

                <h1
                  class="typewrite"
                  data-period="3000"
                  data-type='[ "البرمجة", "تطبيقات", "مواقع"  ]'
                >
                  <span class="wrap"></span>
                </h1>

                <h4>
                  نقدم لك جميع خدمات المواقع الإلكترونية وتطبيقات الموبايل
                  وبرامج إدارة المؤسسات وكل الحلول التقنية الذكية التي تحتاجها
                  في إنشاء وتطوير أعمالك لتنقل أعمالك للمستقبل وتواكب تقنيات
                  الذكاء الاصطناعي.
                </h4>
                <button name="button" aria-label="button">
                  <router-link to="/contact-us" class="nav-link"
                    >تواصل معنا</router-link
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container">
        <div class="desc">
          <h3 class="wow animate__animated animate__bounce">
            ذكاء الدولية توفر لك …..
          </h3>

          <h1
            class="typewrite"
            data-period="3000"
            data-type='[ "البرمجة", "تطبيقات", "مواقع"  ]'
          >
            <span class="wrap"></span>
          </h1>

          <h4>
            نقدم لك جميع خدمات المواقع الإلكترونية وتطبيقات الموبايل وبرامج
            إدارة المؤسسات وكل الحلول التقنية الذكية التي تحتاجها في إنشاء
            وتطوير أعمالك لتنقل أعمالك للمستقبل وتواكب تقنيات الذكاء الاصطناعي.
          </h4>
          <button name="button" aria-label="button">
            <router-link to="/contact-us" class="nav-link"
              >تواصل معنا</router-link
            >
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

// @keyframes slider {
//   30% {
//     background-image: url(../../assets/home/Pic1.jpg);
//   }
//   65% {
//     background-image: url(../../assets/home/Pic2.jpg);
//   }
//   95% {
//     background-image: url(../../assets/home/Pic3.jpg);
//   }
// }
.header {
  width: 100%;
  // background-image: url(../../assets/home/Pic1.jpg);
  // background-size: cover;
  // animation: slider 20s infinite;
  height: 90vh !important;
  position: relative;
  color: white;

  // .carousel-item {
  //   z-index: -1;
  // }
  .overly,
  .carousel-caption {
    width: 100%;
    height: 90vh;
    background: #00000099;
    position: absolute;
    right: 0%;
    top: 0%;
    text-align: justify;
    z-index: 1;
  }
  img {
    height: 90vh;
  }
  .desc {
    align-items: center;
    position: absolute;
    width: 30%;
    margin: auto;
    top: 50%;
    // right: 7%;
    transform: translateY(-50%);
    color: white;

    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 75px;
      color: $main-color;
      padding: 20px 5px;
      margin-right: 50px;
    }
    h2 {
      font-size: 20px;
      padding: 20px 5px;
      font-weight: 300;
      color: white;
    }
    h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 35px;
      color: white;
      padding: 20px 5px;
    }
    button {
      padding: 10px 20px;
      border-radius: 8px;
      border: none;
      font-size: 16px;
      color: white;
      background-color: $main-color;
    }
  }
  @media (max-width: 575.98px) {
    .desc {
      width: 90%;
      h1 {
        line-height: 45px;
      }
      h4 {
        line-height: 27px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .desc {
      width: 80%;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .desc {
      width: 70%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .desc {
      width: 70%;
    }
  }
  @media (min-width: 1120px) and (max-width: 1900px) {
    .desc {
      width: 80%;
      h4 {
        width: 50%;
      }
    }
  }
}
</style>

<script>
export default {
  name: "LandingPageComponent",
  data() {
    return {
      navHeight: 0,
    };
  },
};

var TxtType = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 5000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 1000;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};

window.onload = function () {
  var elements = document.getElementsByClassName("typewrite");
  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute("data-type");
    var period = elements[i].getAttribute("data-period");
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-left: 0.08em solid #00abad}";
  document.body.appendChild(css);
};
</script>
