<template>
  <div class="ServicesMarketing">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/services" class="nav-link"
                    >خدماتنا</router-link
                  >
                </li>
                <span> / </span>
                <li class="breadcrumb-item active">
                  <a> التسويق وتطوير الأعمال </a>
                </li>
              </ol>
            </nav>

            <h1>التسويق وتطوير الأعمال</h1>
            <p>
              نوفر لك كافة وسائل التسويق الالكتروني ونعملُ لك عليها، لتسويق
              أعمالك مُنتجاتك ونشر هويتك لأكبر قدر ممكن من العملاء
              المحتملين.نقدم خدمات تهيئة المواقع لمحركات البحث SEO ،التسويق عبر
              وسائل التواصل الاجتماعي، إنشاء حملات إعلانية، تسويق المحتوى،تحسين
              CRO وكل خدمات التسويق والتسويق الرقمي التي تحتاجها للنمو والتوسع.
            </p>
            <router-link to="contact-us" class="btn"> احجز الان </router-link>
          </div>

          <div class="contain">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg-6 c-text">
                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>التسويق الإلكتروني</h2>
                    </div>

                    <p class="desc">
                      نقدم جميع خدمات التسويق بداية من اختيار استراتيجية التسويق
                      المناسبة لطبيعة العمل بالإضافة إلى إدارة وسائل التواصل
                      الاجتماعي والحملات الإعلانية
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>تطوير خدمات الشركات والمؤسسات</h2>
                    </div>

                    <p class="desc">
                      نقدم كل ما يلزم لتطوير وتحسين خدماتكم ومتجاتكم بما يعزز
                      نجاح ونمو المؤسسة
                    </p>
                  </div>

                  <div class="text">
                    <div class="header">
                      <img
                        src="../../assets//services/ph_check-fill.svg"
                        alt=""
                      />
                      <h2>إنشاء وتطوير الهوية البصرية</h2>
                    </div>

                    <p class="desc">
                      اختر الهوية البصرية الأمثل لطبيعة عملك لتحقق أفضل صورة
                      بصرية للعلامة التجارية أمام عملائك
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-6 c-img">
                  <div class="image">
                    <img
                      loading="lazy"
                      width="100%"
                      height="100%"
                      src="../../assets/services/man.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
  line-height: 32px;
}

.ServicesMarketing {
  padding: 100px 0px 50px !important;
  .services-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 30px;
    border-radius: 8px;
    h1 {
      @include header-h3;
    }
    p {
      @include header-p;
    }
    span {
      color: #616368;
      padding: 5px;
      margin-top: -12px;
      font-weight: 100;
    }
    .breadcrumb-item {
      color: #00abad;
      font-size: 14px;
      font-family: Cairo;
      font-weight: 400;
    }
    .breadcrumb-item.active {
      color: #616368;
    }
    .btn {
      width: auto;
      padding: 10px 20px;
      background: linear-gradient(90deg, #00abad 0%, #28649c 92%);
      color: white;
      margin: 10px auto 0px;
    }
  }
  .contain {
    padding: 50px 0px 0px;
    .text {
      padding: 10px 0px;
      .header {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin: 10px 0px 20px 10px;
        }
        h2 {
          @include header-h3;
          margin: 5px 0px;
        }
      }
      .desc {
        @include header-p;
        color: #0c7abd;
        margin: 0%;
      }
    }
  }

  .image {
    height: 100%;
    width: 90%;
    margin: 0 auto 0 0;
    img {
      border-radius: 8px;
    }
  }
}

@media (max-width: 575.98px) {
  .ServicesMarketing {
    padding: 0px !important;

    .contain {
      padding: 20px 0px;
      .image {
        margin-bottom: 20px;
        width: 100%;
      }
      .c-text {
        order: 2;
      }
      .c-img {
        order: 1;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px;
    .image {
      margin-bottom: 20px;
      width: 100%;
    }
    .c-text {
      order: 2;
    }
    .c-img {
      order: 1;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px;
    .image {
      margin-bottom: 20px;
      width: 100%;
    }
    .c-text {
      order: 2;
    }
    .c-img {
      order: 1;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "ServicesMarketingComponent",
};
</script>
