<template>
  <div class="container">
    <section
      class="about wow animate__animated animate__fadeInLeft"
      data-wow-offset="150"
    >
      <div class="about-text">
        <h2>من نحن</h2>
        <p>
          بدأت رحلتنا في «ذكاء لتقنية المعلومات» باتجاه التطور والاكتشاف. لنقود
          الأفكار نحو التطور وتوليد الفرص ونساهم في وضع حلول ابتكارية للتعامل مع
          التحديات في مجال التقنية ونكون أصحاب الريادة لتطوير الخدمات عبر تقديم
          عددٍ من الخدمات المبتكرة والحلول التقنية المتكاملة لصناعة التحول
          الرقمي في أداء القطاعين العام والخاص وللمساهمة في رفع مستوى الخدمات
          بإستخدام تقنية المعلومات
        </p>
        <button name="button" aria-label="button">
          <router-link to="/about-us" class="nav-link">
            معرفة المزيد</router-link
          >
        </button>
      </div>

      <div class="about-img">
        <img
          src="../../assets/home/Pic4.jpg"
          alt="image"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.about {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 40px;
  .about-img {
    padding: 50px 0px;
    width: 90%;
    height: 100%;
    margin: 0px auto 0px 0px;
    text-align: left !important;
    img {
      width: 80%;
      // height: 100%;
      border-radius: 10px;
    }
  }
  .about-text {
    // padding: 100px 0px;
    width: 90%;
    height: 100%;
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 34px;
      color: $main-color-alt;
    }
    p {
      font-weight: 400;
      line-height: 35px;
    }
    button {
      width: 120px;
      height: 40px;
      padding: 4px 8px 6px;
      border-radius: 8px;
      font-size: 16px;
      color: $main-color;
      background-color: white;
      transition: $main-transition;
      box-shadow: 0 2px 15px rgb(0 0 0 / 20%);
      border: none;
      &:hover {
        color: white;
        background-color: $main-color;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .about {
    display: inline;
    .about-text {
      padding: 45px 10px 0px;
      text-align: justify;
      width: 100%;
      letter-spacing: 0.5px;
    }
    .about-img {
      width: 100%;
      height: 400px;
      img {
        width: 100%;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .about {
    display: inline;
    .about-text {
      padding: 45px 10px 0px;
      text-align: justify;
      width: 100%;
    }
    .about-img {
      width: 100%;
      height: 400px;
      img {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .about {
    .about-text {
      padding: 50px;
      text-align: justify;
      width: 100%;
    }
    .about-text p {
      line-height: 25px;
    }
    .about-img {
      img {
        width: 100%;
        height: 400px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .about {
    .about-text {
      padding: 50px;
    }
    .about-img {
      img {
        width: 100%;
        height: 400px;
      }
    }
  }
}
@media (min-width: 1400px) {
  .about {
    .about-img {
      width: 90%;
    }
  }
}
</style>

<script>
export default {
  name: "AboutComponent",
};
</script>
