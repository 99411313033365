<template>
  <div class="Services">
    <ServicesComponent></ServicesComponent>
  </div>
</template>

<style lang="scss" scoped>
// .ContentUs {
//   width: 100%;
//   height: 100%;
//   background-color: rgb(190, 82, 132);
// }
</style>

<script>
import ServicesComponent from "@/components/services/Services.component.vue";

import { useHead } from "@vueuse/head";
export default {
  name: "ServicesView",
  components: {
    ServicesComponent,
  },
  created() {
    this.$store.commit("setPage", "services");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  setup() {
    useHead({
      title: "ذكاء | خدماتنا",
      meta: [
        {
          name: `description`,
          content: `نساعد الشركات لتطوير البرمجة الخاصة بهم والاستفادة من الاتجاهات الرقمية ،وتلبية إحتياجات السوق ، وبناء ميزة تنافسية`,
        },
        {
          name: `keywords`,
          content: `حلول تقنية متكاملة , التحليل ودراسة تجربة المستخدم,الإستضافة السحابية ,حلول الحماية والأمن السيبراني ,التسويق وتطوير الأعمال ,التدريب والتأهيل , websites , برمجة التطبيقات , تطبيقات الذكاء الاصطناعي  ,   تطبيقات لربح المال , تطبيقات جوجل, software `,
        },
      ],
    });
  },
};
</script>
