<template>
  <section class="jobs">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="overly"></div>
          <video autoplay loop muted loading="lazy" width="100%" height="100%">
            <source src="../../assets/home/join-us-section-2.mp4" />
          </video>

          <div class="jobs-text">
            <h2>انضم إلينا.</h2>
            <p>دعونا نبني أشياء عظيمة معًا!</p>

            <router-link to="/jobs" class="nav-link">
              <button name="button" aria-label="button">
                تعرف علي الوظائف المتاحه
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.jobs {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .overly {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #79797959;
    z-index: 1;
  }
  video {
    position: absolute;
    top: 0%;
    left: 0%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .jobs-text {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 70px;
    color: white;
    text-align: center;
    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 44px;
      color: white;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 44px;
      letter-spacing: 1px;
      color: white;
    }
    button {
      width: 250px;
      height: 40px;
      padding: 4px 8px 6px;
      border-radius: 8px;
      font-size: 16px;
      color: $main-color-alt;
      background-color: $section-background;
      transition: var(--main-transition);
      box-shadow: 0 2px 15px rgb(0 0 0 / 20%);
      border: none;
      &:hover {
        color: white;
        background-color: $main-color;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .jobs {
    height: auto;
    padding: 200px 0px;
  }
}
</style>

<script>
export default {
  name: "JobComponent",
};
</script>
