<template>
  <section class="our-services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex">
            <div class="our-services-text">
              <h2 class="head-page">خدماتنا</h2>
              <p>
                نساعد عملائنا على تبسيط أعمالهم من خلال مجموعة الحلول والخدمات
                والتطبيقات التي تساعد على تسهيل سير وتكامل الأعمال بالإضافة إلى
                الدعم التقني والمتابعة المستمرة.
              </p>

              <button name="button" aria-label="button">
                <router-link to="/services" class="nav-link">
                  عرض المزيد</router-link
                >
              </button>
            </div>

            <div class="our-services-bar">
              <ul>
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <li>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="flush-headingOne"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        حلول تقنية متكاملة
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <img
                            loading="lazy"
                            width="100%"
                            height="100%"
                            class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                            src="../../assets/home/fa-solid_laptop-code.svg"
                            alt="icons"
                          />
                          <p>
                            ننشئ ونطور الحلول التقنية للشركات والمؤسسات في
                            المراحل المختلفة بداية من الإنشاء حتى الانطلاق
                            بالإضافة للدعم التقني والمتابعة المستمرة
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="flush-headingTwo"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        تسويق وتطوير الأعمال
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <img
                            loading="lazy"
                            width="100%"
                            height="100%"
                            class="wow animate__animated animate__animated wow animate__animated animate__fadeInDown"
                            src="../../assets/home/uil_chart-line.svg"
                            alt="icons"
                          />
                          <p>
                            نوفر لك كافة وسائل التسويق الالكتروني ونعملُ لك
                            عليها، لتسويق أعمالك مُنتجاتك ونشر هويتك لأكبر قدر
                            ممكن من العملاء المحتملين.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                        id="flush-headingThree"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        الدراسات والإستشارات
                      </h2>
                      <div
                        id="flush-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <img
                            loading="lazy"
                            width="100%"
                            height="100%"
                            class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                            src="../../assets/home/ph_users-three-fill.svg"
                            alt="icons"
                          />
                          <p>
                            تواصل مع طاقمنا الاستشاري للحصول على خدمات استشارية
                            في المجالات التقنية والإدارية تدعم نمو أعمالك.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="accordion-item">
                      <h2
                        class="accordion-header wow animate__animated animate__animated wow animate__animated animate__fadeInDown"
                        id="flush-headingFour"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        تدريب وتأهيل
                      </h2>
                      <div
                        id="flush-collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <img
                            loading="lazy"
                            width="100%"
                            height="100%"
                            class="wow animate__animated animate__animated wow animate__animated animate__fadeInDown"
                            src="../../assets/home/icon-park-solid_every-user.svg"
                            alt="icons"
                          />
                          <p>
                            نهدف في ذكاء الدولية إلى تطوير أداء المؤسسات ورفع
                            مهارة الأفراد من خلال إعداد البرامج التدريبية التي
                            توفر الأدوات اللازمة لزيادة الخبرات والمهارات .
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>

            <div class="our-services-sm">
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="d-flex">
                      <div
                        class="our-services-head wow animate__animated animate__fadeInUp"
                      >
                        <img
                          loading="lazy"
                          width="100%"
                          height="100%"
                          class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                          src="../../assets/home/fa-solid_laptop-code.svg"
                          alt="icons"
                        />
                        <h2>حلول تقنية متكاملة</h2>
                      </div>

                      <div class="our-services-text-sm">
                        <p>
                          ننشئ ونطور الحلول التقنية للشركات والمؤسسات في المراحل
                          المختلفة بداية من الإنشاء حتى الانطلاق بالإضافة للدعم
                          التقني والمتابعة المستمرة
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex">
                      <div
                        class="our-services-head wow animate__animated animate__fadeInUp"
                      >
                        <img
                          loading="lazy"
                          width="100%"
                          height="100%"
                          class="wow animate__animated animate__animated wow animate__animated animate__fadeInDown"
                          src="../../assets/home/uil_chart-line.svg"
                          alt="icons"
                        />
                        <h2>تسويق وتطوير الأعمال</h2>
                      </div>

                      <div class="our-services-text-sm">
                        <p>
                          نوفر لك كافة وسائل التسويق الالكتروني ونعملُ لك عليها،
                          لتسويق أعمالك مُنتجاتك ونشر هويتك لأكبر قدر ممكن من
                          العملاء المحتملين.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex">
                      <div
                        class="our-services-head wow animate__animated animate__fadeInUp"
                      >
                        <img
                          loading="lazy"
                          width="100%"
                          height="100%"
                          class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                          src="../../assets/home/ph_users-three-fill.svg"
                          alt="icons"
                        />
                        <h2>الدراسات والإستشارات</h2>
                      </div>

                      <div class="our-services-text-sm">
                        <p>
                          تواصل مع طاقمنا الاستشاري للحصول على خدمات استشارية في
                          المجالات التقنية والإدارية تدعم نمو أعمالك.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div class="d-flex">
                      <div
                        class="our-services-head wow animate__animated animate__fadeInUp"
                      >
                        <img
                          class="wow animate__animated animate__animated wow animate__animated animate__fadeInDown"
                          src="../../assets/home/icon-park-solid_every-user.svg"
                          alt="icons"
                        />
                        <h2>تدريب وتأهيل</h2>
                      </div>

                      <div class="our-services-text-sm">
                        <p>
                          نهدف في ذكاء الدولية إلى تطوير أداء المؤسسات ورفع
                          مهارة الأفراد من خلال إعداد البرامج التدريبية التي
                          توفر الأدوات اللازمة لزيادة الخبرات والمهارات .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1053_9188)">
                        <path
                          d="M3.28292 10.9404C3.00202 11.2216 2.84424 11.6029 2.84424 12.0004C2.84424 12.3979 3.00202 12.7791 3.28292 13.0604L8.93892 18.7184C9.07825 18.8577 9.24366 18.9682 9.42571 19.0436C9.60776 19.119 9.80287 19.1579 9.99992 19.1579C10.197 19.1579 10.3921 19.119 10.5741 19.0436C10.7562 18.9682 10.9216 18.8577 11.0609 18.7184C11.2003 18.579 11.3108 18.4136 11.3862 18.2316C11.4616 18.0495 11.5004 17.8544 11.5004 17.6574C11.5004 17.4603 11.4616 17.2652 11.3862 17.0832C11.3108 16.9011 11.2003 16.7357 11.0609 16.5964L7.96492 13.5004h29.4999C19.8977 13.5004 20.2793 13.3423 20.5606 13.061C20.8419 12.7797 20.9999 12.3982 20.9999 12.0004C20.9999 11.6026 20.8419 11.221 20.5606 10.9397C20.2793 10.6584 19.8977 10.5004 19.4999 10.5004H7.96492L11.0609 7.40438C11.3422 7.12298 11.5001 6.74138 11.5 6.34352C11.5 5.94567 11.3418 5.56414 11.0604 5.28288C10.779 5.00161 10.3974 4.84366 9.99956 4.84375C9.60171 4.84384 9.22018 5.00198 8.93892 5.28338L3.28292 10.9404Z"
                          fill="#D0D0D2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1053_9188">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1053_9192)">
                        <path
                          d="M20.7171 10.9404C20.998 11.2216 21.1558 11.6029 21.1558 12.0004C21.1558 12.3979 20.998 12.7791 20.7171 13.0604L15.0611 18.7184C14.9217 18.8577 14.7563 18.9682 14.5743 19.0436C14.3922 19.119 14.1971 19.1579 14.0001 19.1579C13.803 19.1579 13.6079 19.119 13.4259 19.0436C13.2438 18.9682 13.0784 18.8577 12.9391 18.7184C12.7997 18.579 12.6892 18.4136 12.6138 18.2316C12.5384 18.0495 12.4996 17.8544 12.4996 17.6574C12.4996 17.4603 12.5384 17.2652 12.6138 17.0832C12.6892 16.9011 12.7997 16.7357 12.9391 16.5964L16.0351 13.5004H4.50008C4.10226 13.5004 3.72073 13.3423 3.43942 13.061C3.15812 12.7797 3.00008 12.3982 3.00008 12.0004C3.00008 11.6026 3.15812 11.221 3.43942 10.9397C3.72073 10.6584 4.10226 10.5004 4.50008 10.5004h26.0351L12.9391 7.40438C12.6578 7.12298 12.4999 6.74138 12.5 6.34352C12.5 5.94567 12.6582 5.56414 12.9396 5.28288C13.221 5.00161 13.6026 4.84366 14.0004 4.84375C14.3983 4.84384 14.7798 5.00198 15.0611 5.28338L20.7171 10.9404Z"
                          fill="#00ABAD"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1053_9192">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(-1 0 0 1 24 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.our-services {
  margin: 0px;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 100px 0px;
  .d-flex {
    align-items: center;
  }
  .our-services-text {
    width: 100%;
    height: 100%;

    h2.head-page {
      font-size: 18px;
      font-weight: 700;
      line-height: 34px;
      color: $main-color-alt;
    }

    p {
      line-height: 30px;
      color: black;
      letter-spacing: 1px;
      width: 80%;
    }
    button {
      width: 120px;
      height: 40px;
      padding: 4px 8px 6px;
      border-radius: 8px;
      font-size: 16px;
      color: white;
      background-color: $main-color;
      transition: $main-transition;
      box-shadow: 0 2px 15px rgb(0 0 0 / 20%);
      border: none;
      &:hover {
        background-color: white;
        color: $main-color;
      }
    }
  }

  .our-services-bar {
    width: 90%;
    height: 100%;

    ul {
      padding: 10px 0px;
      position: relative;

      &::after {
        content: "";
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0px;
        z-index: 0;
        background-color: #d0d0d2;
      }

      li {
        color: var(--main-color);
        margin: 10px 5px;
        font-weight: 600;
        padding: 5px 20px 5px 0px;
        position: relative;

        h2 {
          font-size: 18px;
          font-weight: 700;
          color: #00abad;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0px;
          line-height: 30px;
          color: black;
          letter-spacing: 1px;
          padding: 10px 10px 0px 0px;
        }

        img {
          position: absolute;
          right: -80px;
          top: 0;
          width: 80px;
          height: 100%;
          border-left: 6px solid #00abad;
          padding-left: 10px;
          border-radius: 2%;
          z-index: 1;
        }
      }
    }
  }
}

.accordion-body {
  padding: 0px;
}

.our-services-sm {
  display: none;
  padding: 0px 50px 50px;
  width: 80%;
  margin: auto;

  .our-services-head {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
  }

  .carousel-inner {
    border: 1px solid #cef9f1;
    padding: 20px 40px;
    position: relative;
  }

  .our-services-head {
    img {
      float: right;
      width: 40px;
      height: 40px;
    }

    h2 {
      color: #00abad;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      margin: 0px 10px;
    }
  }

  .our-services-text-sm {
    padding: 20px 0px 0px;
    text-align: center;
  }

  .carousel-control-prev {
    top: 30px;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  .carousel-control-next {
    top: 30px;
    position: relative;
    justify-content: center;
    width: 100%;
    left: 20px;
    top: -3px;
  }

  .carousel-control-prev {
    right: 20px;
  }

  //  .our-services .our-services-bar {
  // 		display: block;
  // 	}
  //  	.our-services-sm {
  // 		display: none;
  // 		width: 100%;
  // 	}
  // 	.our-services .our-services-bar {
  // 		padding: 20px 60px 20px 0px;
  // 		width: 100%;
  // 	}
  // 	.our-services .our-services-bar ul li p {
  // 		font-size: 14px;
  // 		font-weight: 700;
  // 		line-height: 18px;
  // 	}
}

@media (max-width: 575.98px) {
  .our-services {
    padding: 50px 0px;
    .d-flex {
      display: inline-block !important;
    }
    text-align: center;
    .our-services-text {
      width: 100%;
      text-align: center;
      p {
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        letter-spacing: 0.2px;
        margin: 20px auto;
      }
    }
    .our-services-bar {
      display: none;
    }
    .our-services-sm {
      margin-top: 50px;
      padding: 0px;
      display: block;
      width: 100%;
    }
    p {
      text-align: justify;
      font-size: 18px;
      line-height: 28px;
    }
    .carousel-control-next {
      right: -10px;
      top: 4px;
      margin: 0%;
      padding: 0%;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .our-services {
    text-align: center;
    padding: 0%;

    .d-flex {
      display: inline-block !important;
    }
    .our-services-text {
      padding: 50px 0px !important;
      width: 100%;
    }
    .our-services-text p {
      font-size: 18px;
      line-height: 28px;
      text-align: justify;
      margin: 20px auto;
    }
    .our-services-bar {
      width: 100%;
      display: none;
    }
    .our-services-sm {
      padding: 0px 0px 20px;
      display: block;
      width: 100%;
    }
    p {
      text-align: justify;
      font-size: 18px;
      line-height: 28px;
    }
    .carousel-control-next {
      right: -10px;
      top: 4px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .our-services {
    padding: 0%;
    .our-services-bar {
      width: 100%;
      padding: 50px 0px;

      ul {
        margin: 0px 20px 0px 0px;

        li {
          p {
            line-height: 26px;
          }
          img {
            right: -70px !important;
          }
        }
        &::after {
          right: 10px !important;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "OurServicesComponent",

  data() {
    return {
      accordions: [],
      currentSlide: 0,
      intervalDuration: 4000,
      autoPlayInterval: null,
    };
  },
  methods: {
    showSlide(index) {
      this.currentSlide = index;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.accordions.length;
    },
  },
  mounted() {
    this.accordions = document.querySelectorAll(".accordion-collapse");

    this.autoPlayInterval = setInterval(this.nextSlide, this.intervalDuration);

    this.accordions.forEach((accordion) => {
      // console.log(index);
      accordion.addEventListener("mouseenter", () => {
        clearInterval(this.autoPlayInterval);
      });

      accordion.addEventListener("mouseleave", () => {
        this.autoPlayInterval = setInterval(
          this.nextSlide,
          this.intervalDuration
        );
      });
    });

    this.showSlide(this.currentSlide);
  },
};
</script>
