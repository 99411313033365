<template>
  <div class="ServicesMarketing">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="services-header">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/services" class="nav-link"
                    >خدماتنا</router-link
                  >
                </li>
                <span> / </span>
                <li class="breadcrumb-item">
                  <router-link to="/services-lap">
                    حلول تقنية متكاملة</router-link
                  >
                </li>
                <span> / </span>
                <li class="breadcrumb-item active">
                  <a> حلول تطوير البرمجيات </a>
                </li>
              </ol>
            </nav>
            <div class="d-flex">
              <img
                loading="lazy"
                width="100%"
                height="100%"
                src="../../assets/home/icon-park-outline_code-laptop.svg"
              />
              <h1>حلول تطوير البرمجيات</h1>
            </div>
            <router-link to="contact-us" class="btn"> احجز الان </router-link>
          </div>

          <div class="contain">
            <div class="col-12">
              <div class="row">
                <ul class="text">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <li>
                      <div class="accordion-item">
                        <div class="header">
                          <img
                            src="../../assets/services/ph_check-fill.svg"
                            alt=""
                          />
                          <h2
                            class="accordion-header"
                            id="flush-headingOne"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            أنظمة تخطيط موارد المؤسساتERP
                            <span>
                              <img
                                src="../../assets/home/eva_arrow-up-fill.svg"
                              />
                            </span>
                          </h2>
                        </div>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="row">
                            <div class="accordion-body">
                              <div class="col-12 col-md-6 body-img">
                                <img
                                  class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                                  src="../../assets/services/5.jpg"
                                  alt="icons"
                                />
                              </div>
                              <div class="col-12 col-md-6 body-text">
                                <p>
                                  احصل على برنامج ERP متكام ليساعدك في إدارة كل
                                  جوانب أعمالك بواجهة سهلة الاستخدام تدعم اللغة
                                  العربية تحكم في إصدار الفواتير وإدارة المبيعات
                                  والمخزون وإدارة علاقات العملاء والحسابات
                                  ومتابعة دورة العمل لديك من مكان واحد. مع
                                  تطبيقات إدارة شاملة وقابلة للتخصيص لتلبية
                                  احتياجات أعمالك، تستخدمها في أي وقت ومن أي
                                  مكان لكونه نظامًا سحابيًا بإجراءات أمان قوية
                                  تضمن سلامة بياناتك.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="accordion-body-2">
                              <div class="col-12">
                                <ul>
                                  <div class="col-md-2 col-md-3">
                                    <li>
                                      <img
                                        src="../../assets/services/icons/fluent_money-calculator-24-regular.svg"
                                        alt=""
                                      />
                                      <h5>إدارة الشئون المالية والمحاسبة</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/vaadin_line-bar-chart.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المبيعات</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/material-symbols_note-outline.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المستندات</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/ps_data-board.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المشاريع</h5>
                                    </li>
                                  </div>
                                  <div class="col-md-2 col-md-3">
                                    <li>
                                      <img
                                        src="../../assets/services/icons/clarity_clipboard-outline-badged.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المخزون</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/nimbus_marketing.svg"
                                        alt=""
                                      />
                                      <h5>إدارة العلاقات العامة والتسويق</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/tabler_settings-plus.svg"
                                        alt=""
                                      />
                                      <h5>إدارة الخدمات</h5>
                                    </li>
                                  </div>
                                  <div class="col-md-2 col-md-3">
                                    <li>
                                      <img
                                        src="../../assets/services/icons/fluent_desk-24-filled.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المخازن</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/carbon_task-settings.svg"
                                        alt=""
                                      />
                                      <h5>إدارة اللإنتاج</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/fluent_clipboard-note-16-regular.svg"
                                        alt=""
                                      />
                                      <h5>إدارة نقاط البيع</h5>
                                    </li>
                                  </div>
                                  <div class="col-md-2 col-md-3">
                                    <li>
                                      <img
                                        src="../../assets/services/icons/ion_cart-outline.svg"
                                        alt=""
                                      />
                                      <h5>إدارة المشتريات</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/iconamoon_certificate-badge.svg"
                                        alt=""
                                      />
                                      <h5>إدارة الجودة</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/tabler_users-group.svg"
                                        alt=""
                                      />
                                      <h5>إدارة الهيكل التنظيمي</h5>
                                    </li>
                                    <li>
                                      <img
                                        src="../../assets/services/icons/pepicons-pencil_cv.svg"
                                        alt=""
                                      />
                                      <h5>إدارة الموارد البشرية</h5>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="accordion-item">
                        <div class="header">
                          <img
                            src="../../assets//services/ph_check-fill.svg"
                            alt=""
                          />
                          <h2
                            class="accordion-header"
                            id="flush-headingFive"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            منصات تعليمية
                            <img
                              src="../../assets/home/eva_arrow-up-fill.svg"
                            />
                          </h2>
                        </div>
                        <div
                          id="flush-collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div class="col-6 body-img">
                              <img
                                class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                                src="../../assets/services/web.png"
                                alt="icons"
                              />
                            </div>
                            <div class="col-6 body-text">
                              <p>
                                التحرك نحو توفير التعليم والتدريب الخاص بك
                                الخدمات عبر الإنترنت باستخدام أفضل التصاميم
                                للتصميم موقع المدرسة / الحضانة / الكلية /
                                الجامعة / التدريب /المركز / موقع المدرب / وغيرها
                                الكثير. إضافة العديد من الميزات لعملائك للتواصل
                                معك بسهولة، وتساهم في تطويره وحفظه مع الحلول
                                التقنية، والوقوف بين المنافسين، تحقيق أفضل
                                النتائج وتحسين التعليمية العملية، حيث أننا نقدم
                                موقعًا يعمل بمثابة الموقع الرئيسي وجهة لفتح فروع
                                جديدة والتوسع المزيد والمزيد.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="accordion-item">
                        <div class="header">
                          <img
                            src="../../assets//services/ph_check-fill.svg"
                            alt=""
                          />
                          <h2
                            class="accordion-header"
                            id="flush-headingTwo"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            تطبيقات الويب
                            <img
                              src="../../assets/home/eva_arrow-up-fill.svg"
                            />
                          </h2>
                        </div>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div class="col-6 body-img">
                              <img
                                class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                                src="../../assets/services/6.jpg"
                                alt="icons"
                              />
                            </div>
                            <div class="col-6 body-text">
                              <p>
                                نقدم لك في ذكاء خبرة أكثر من 15 عامًا في تصميم
                                تطبيقات الويب ليناسب نشاطك الإلكتروني في مختلف
                                المجالات.
                              </p>
                              <ul>
                                <li>
                                  بوابات المعلومات: المواقع الإخبارية ،الرياضية
                                  والتعليمية
                                </li>
                                <li>الألعاب الالكترونية</li>
                                <li>
                                  الخدمات عبر الإنترنت: الخدمات المصرفية وخدمات
                                  البحث
                                </li>
                                <li>
                                  منصات التعلم التفاعلية ونظام إدارة التعلم LMS
                                </li>
                                <li>المواقع الحكومية</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="accordion-item">
                        <div class="header">
                          <img
                            src="../../assets//services/ph_check-fill.svg"
                            alt=""
                          />
                          <h2
                            class="accordion-header"
                            id="flush-headingThree"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            منصات التجارة الإلكترونية
                            <img
                              src="../../assets/home/eva_arrow-up-fill.svg"
                            />
                          </h2>
                        </div>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div class="col-6 body-img">
                              <img
                                class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                                src="../../assets/services/3.jpg"
                                alt="icons"
                              />
                            </div>
                            <div class="col-6 body-text">
                              <p>
                                احصل على منصة تجارة إلكترونية متكاملة مع تطبيقات
                                إدارة المخزون وعلاقات العملاء لتوفير تجربة سهلة
                                لإدارة كل عمليات التجارة الإلكترونية
                              </p>
                              <ul>
                                <li>
                                  تصميم عصرى مميز وسهل يناسب جميع الاجهزة
                                  والمتصفحات .
                                </li>
                                <li>
                                  نظام الشات والدعم الفني يعمل على متابعة وحل
                                  مشكلات عملائك
                                </li>
                                <li>
                                  وفرنا لوحة تحكم سهلة للإدارة لا تحتاج إلى خبرة
                                  في البرمجيات لتكون سهل الاستخدام..
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="accordion-item">
                        <div class="header">
                          <img
                            src="../../assets//services/ph_check-fill.svg"
                            alt=""
                          />
                          <h2
                            class="accordion-header"
                            id="flush-headingFour"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            تطبيقات الجوال
                            <img
                              src="../../assets/home/eva_arrow-up-fill.svg"
                            />
                          </h2>
                        </div>
                        <div
                          id="flush-collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            <div class="col-6 body-img">
                              <img
                                class="wow animate__animated animate__animated wow animate__animated animate__fadeInUp"
                                src="../../assets/services/2.jpg"
                                alt="icons"
                              />
                            </div>
                            <div class="col-6 body-text">
                              <p>
                                نفذ فكرتك الآن مع تطبيقات الموبايل وحقق رؤيتك من
                                خلال التطبيقات عالية الجودة المتوافقة مع أنظمة
                                التشغيل المختلفة مثل IOS , Android
                              </p>
                              <ul>
                                <li>
                                  يتمتع أعضاء فريقنا بالمعرفة اللازمة بلغات
                                  البرمجة المختلفة المستخدمة في تطوير تطبيقات
                                  الجوال
                                </li>
                                <li>
                                  نصمم لك واجهة مستخدم جذابة وسهلة الاستخدام على
                                  تطبيقات الموبايل
                                </li>
                                <li>
                                  ننشئ التطبيقات ونوفر دعم وصيانة التطبيقات
                                  باستمرار بعد إطلاقها ونحدث التطبيقات لتحافظ
                                  على أفضل أداء.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

@mixin header-h3 {
  color: $main-color-alt;
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px 20px;
}
@mixin header-p {
  color: #12151c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0px;
  line-height: 32px;
}

.ServicesMarketing {
  padding: 100px 0px 50px;
  .services-header {
    background-color: #cceeef;
    font-size: 24px;
    font-weight: 700;
    padding: 30px;
    border-radius: 8px;
    h1 {
      @include header-h3;
    }

    span {
      color: #616368;
      padding: 5px;
      margin-top: -12px;
      font-weight: 100;
    }
    .breadcrumb-item {
      color: #00abad;
      font-size: 14px;
      font-family: Cairo;
      font-weight: 400;
    }
    .breadcrumb-item.active {
      color: #616368;
    }
    img {
      width: 40px;
      height: 40px;
      margin: 5px 0px 5px 10px;
    }
    .btn {
      width: auto;
      padding: 10px 20px;
      background: linear-gradient(90deg, #00abad 0%, #28649c 92%);
      color: white;
      margin: 10px auto 0px;
    }
  }
  .contain {
    padding: 70px 0px 0px;
    .text {
      position: relative;
      padding: 10px 0px;
      .header {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin: 10px 0px 20px 10px;
        }
        h2 {
          @include header-h3;
          margin: 5px 0px;
        }
      }
      &:active img {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }
      &:focus img {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }

      .accordion-body {
        min-height: 400px;
        padding: auto 0%;
        .body-img {
          text-align: left;
          position: absolute;
          top: 0px;
          left: 0%;
          z-index: 1;
          img {
            height: 100%;
            width: 90%;
          }
        }
        .body-text {
          @include header-p;
          color: #0c7abd;
          letter-spacing: 1.1px;
          line-height: 40px;
          ul {
            list-style: disc;
            padding: 0px 20px;
          }
          li {
            color: #616368;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }
      }
      .accordion-body-2 ul {
        display: flex;

        li {
          display: flex;
          margin: 20px auto;
        }
        img {
          width: 40px;
          height: 40px;
        }
        h5 {
          color: #616368;
          font-size: 16px;
          font-weight: 400;
          margin: 10px;
          letter-spacing: 1px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ServicesMarketing {
    padding: 100px 0px 0px;
    .contain {
      padding: 20px 0px;
      .text {
        .accordion-item {
          position: relative;
          .accordion-body {
            height: auto;

            .body-img {
              width: 100%;
              margin: 70px auto;
              clear: both;
              img {
                height: 400px;
                width: 100%;
              }
            }
            .body-text {
              margin: 400px auto 0px;
              width: 100%;
            }
          }
          .accordion-body-2 ul {
            display: inline-block;
            width: 100%;

            li {
              display: inline-block;
              width: 50%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ServicesMarketing {
    padding: 100px 0px 0px;

    .contain {
      padding: 20px 0px;
      .text {
        .accordion-item {
          position: relative;
          .accordion-body {
            height: auto;

            .body-img {
              width: 100%;
              margin: 70px auto;
              clear: both;
              img {
                height: 400px;
                width: 100%;
              }
            }
            .body-text {
              margin: 400px auto 0px;
              width: 100%;
            }
          }
          .accordion-body-2 ul {
            display: inline-block;
            width: 100%;

            li {
              display: inline-block;
              width: 50%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ServicesMarketing .contain {
    padding: 20px 0px;
    .text {
      .accordion-item {
        position: relative;
        .accordion-body {
          height: auto;
          .body-img {
            width: 100%;
            margin: 70px auto;
            clear: both;
            img {
              height: 400px;
              width: 100%;
            }
          }
          .body-text {
            margin: 400px auto 0px;
            width: 100%;
          }
        }
        .accordion-body-2 ul {
          width: 100%;

          li {
            display: inline-block;
            width: 100%;
            img {
              width: 30px;
              height: 30px;
              margin: 0;
            }
            h5 {
              margin: 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .text {
    .accordion-item {
      position: relative;
      .accordion-body {
        height: auto;

        .body-img {
          width: 50%;
          height: 400px;
          img {
            height: 100%;
          }
        }
        .body-text {
          margin: 0px auto 10px;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
}
</style>

<script>
export default {
  name: "ServicesSolutionComponent",
};
</script>
